import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    ExperimentOutlined,
    FireOutlined,
    ShopOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    TeamOutlined,
    ToolOutlined,
    WarningOutlined,
    QuestionOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import FireExtinguisherOutlinedIcon from "@mui/icons-material/FireExtinguisherOutlined";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import TheaterComedyOutlinedIcon from "@mui/icons-material/TheaterComedyOutlined";
import { useTranslation } from "react-i18next";

const { Header, Content, Sider } = Layout;

const AnaMenu = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [collapsed, setCollapsed] = useState(false);
    const { t } = useTranslation();

    const items = [
        {
            key: "kisiList",
            icon: <TeamOutlined style={{ marginLeft: -20 }} />,
            label: "Kişi",
            disabled: false,
        },
        {
            key: "firmaList",
            icon: <ShopOutlined style={{ marginLeft: -20 }} />,
            label: "Firma",
        },
        {
            key: "kursList",
            icon: <CollectionsBookmarkOutlinedIcon style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.kursList"),
        },
        {
            key: "egitimList",
            icon: <SchoolOutlinedIcon style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.egitimList"),
        },
        {
            key: "tableA",
            icon: <ExperimentOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.tableA"),
        },
        {
            key: "riskList",
            icon: <WarningOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.riskAnalizi"),
        },
        {
            key: "bildirimList",
            icon: <MailOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.bildirim"),
        },
        {
            key: "rolList",
            icon: <TheaterComedyOutlinedIcon style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.rolList"),
        },
        {
            key: "tespitList",
            icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.tespitList"),
        },
        {
            key: "tatbikatList",
            icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.tatbikatList"),
        },
        {
            key: "envanterList",
            icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.envanterList"),
        },
        {
            key: "adpList",
            icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.adpList"),
        },
        {
            key: "ekipmanlar",
            icon: <ToolOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.ekipmanlar"),
            children: [
                {
                    key: "ekipmanList",
                    icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.ekipmanList"),
                },
                {
                    key: "yanginTupuList",
                    icon: <FireOutlined style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.yanginTupuList"),
                },
                {
                    key: "yanginDolabiList",
                    icon: <FireOutlined style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.yanginDolabiList"),
                },
                {
                    key: "merdivenList",
                    icon: <StairsOutlinedIcon style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.yanginDolabiList"),
                },
            ],
        },
        {
            key: "uetds",
            icon: <ToolOutlined style={{ marginLeft: -20 }} />,
            label: t("sayfaBaslik.uetds"),
            children: [
                {
                    key: "uetdsTc",
                    icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.uetdsTc"),
                },
                {
                    key: "uetdsPlaka",
                    icon: <QuestionOutlined style={{ marginLeft: -20 }} />,
                    label: t("sayfaBaslik.uetdsPlaka"),
                },
            ],
        },
    ];

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Menu
                mode="inline"
                theme="dark"
                items={items}
                onClick={(item) => {
                    navigate(`/${item.key}`);
                }}
                style={{ height: "100%" }}
            />
        </Sider>
    );
};
export default AnaMenu;
