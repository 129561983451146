import { Form, Button, DatePicker, Input, Layout, notification, Popconfirm, Radio, Select, Spin, Tabs, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useTatbikatiGetir, useTatbikatGuncelle, useTatbikatSil } from "../hooks/useTatbikat";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Content } = Layout;

function TatbikatGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { tatbikat } = useTatbikatiGetir(id);
    const { tatbikatGuncelle } = useTatbikatGuncelle(id);
    const { tatbikatSil } = useTatbikatSil(id);

    const tatbikatKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="tatbikatKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const tatbikatSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="tatbikatSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                tatbikatSil(id);
                navigate("/tatbikatList");
            }}
            style={{ width: 400 }}
        >
            <Button key="tatbikatSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (tatbikat) {
            form.setFieldsValue({
                isim: tatbikat.isim,
                tur: tatbikat.tur,
                yuruten: tatbikat.yuruten,
                onaylayan: tatbikat.onaylayan,
                konum: tatbikat.konum,
                amac: tatbikat.amac,
                senaryo: tatbikat.senaryo,
                dogru: tatbikat.dogru,
                yanlis: tatbikat.yanlis,
                sonuc: tatbikat.sonuc,
            });
            setLoading(false);
        }
    }, [tatbikat]);

    const onSubmit = (e) => {
        setIsPending(true);
        console.log("form bilgisi : ", e);
        tatbikatGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="tatbikatGuncelleKmyHeader"
                        caller="sayfaBaslik.tatbikatGuncelle"
                        buttons={[tatbikatKaydetButon, tatbikatSilButon]}
                        backURL="/tatbikatList"
                    />
                    {contextHolder}
                    <Form.Item name="isim" label={t("tatbikat.isim")}>
                        <Input placeholder={t("tatbikat.isimHint")} />
                    </Form.Item>
                    <Form.Item name="tur" label={t("tatbikat.tur")}>
                        <Input placeholder={t("tatbikat.turHint")} />
                    </Form.Item>
                    <Form.Item name="yuruten" label={t("tatbikat.yuruten")}>
                        <Input placeholder={t("tatbikat.yurutenHint")} />
                    </Form.Item>
                    <Form.Item name="onaylayan" label={t("tatbikat.onaylayan")}>
                        <Input placeholder={t("tatbikat.onaylayanHint")} />
                    </Form.Item>
                    <Form.Item name="konum" label={t("tatbikat.konum")}>
                        <Input placeholder={t("tatbikat.konumHint")} />
                    </Form.Item>
                    <Form.Item name="amac" label={t("tatbikat.amac")}>
                        <ReactQuill placeholder={t("tatbikat.amacHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                    <Form.Item name="senaryo" label={t("tatbikat.senaryo")}>
                        <ReactQuill placeholder={t("tatbikat.senaryoHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                    <Form.Item name="dogru" label={t("tatbikat.dogru")}>
                        <ReactQuill placeholder={t("tatbikat.dogruHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                    <Form.Item name="yanlis" label={t("tatbikat.yanlis")}>
                        <ReactQuill placeholder={t("tatbikat.yanlisHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                    <Form.Item name="sonuc" label={t("tatbikat.sonuc")}>
                        <ReactQuill placeholder={t("tatbikat.sonucHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default TatbikatGuncelle;
