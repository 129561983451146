import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

export const useAksiyonEkle = () => {
    const [aksiyon, setAksiyon] = useState();
    const { accessToken } = useGetToken();

    const aksiyonEkle = async (e) => {
        console.log("eklenecek : ", e);
        fetch(`http://89.19.24.18:3001/Aksiyon/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data.body);
            });
    };

    return { aksiyonEkle };
};

export const useAksiyonGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const aksiyonGuncelle = async ({ id, e }) => {
        console.log("aksiyon id: ", e);
        e.aksiyonID = id;
        fetch(`http://89.19.24.18:3001/Aksiyon/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { aksiyonGuncelle };
};

export const useAksiyonSil = (id) => {
    const { accessToken } = useGetToken();
    const aksiyonSil = async () => {
        fetch(`http://89.19.24.18:3001/Aksiyon/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { aksiyonSil };
};

export const useAksiyonlariGetir = () => {
    const { accessToken } = useGetToken();
    const [aksiyonlar, setAksiyonlar] = useState([]);
    const aksiyonlariGetir = async () => {
        fetch("http://89.19.24.18:3001/Aksiyon", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAksiyonlar(data);
            });

        return () => aksiyonlar;
    };
    useEffect(() => {
        aksiyonlariGetir();
    }, []);

    return { aksiyonlar };
};

export const useAksiyonGetir = (id) => {
    const [aksiyon, setAksiyon] = useState([]);
    const { accessToken } = useGetToken();
    const aksiyonGetir = async () => {
        fetch(`http://89.19.24.18:3001/Aksiyon/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAksiyon(data);
            });

        return () => aksiyon;
    };

    useEffect(() => {
        aksiyonGetir();
    }, []);

    return { aksiyon };
};
