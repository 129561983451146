import { Form, Button, Input, message, notification, Popconfirm, Spin, Switch, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useKursBolumunuGetir, useKursBolumGuncelle, useKursBolumSil } from "../hooks/useKursBolum";
import KmyHeader from "./KmyHeader";
import ReactPlayer from "react-player";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { TextArea } = Input;

function KursBolumGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [kursID, setKursID] = useState();
    const [videoURL, setVideoURL] = useState();

    const { kursBolumu } = useKursBolumunuGetir(id);
    const { kursBolumGuncelle } = useKursBolumGuncelle(id);
    const { kursBolumSil } = useKursBolumSil(id);

    const kursBolumKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} key="chapterSaveButton">
            {t("genel.kaydet")}
        </Button>
    );

    const kursBolumSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="kursBolumSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                kursBolumSil(id);
                navigate(`/kursGuncelle/${kursID}`);
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger key="kursBolumSilButon">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (kursBolumu) {
            form.setFieldsValue({
                kursBolumIsmi: kursBolumu.kursBolumIsmi,
                kursBolumIcerik: kursBolumu.kursBolumIcerik,
                yayinlandiMi: kursBolumu.yayinlandiMi,
                videoURL: kursBolumu.videoURL,
            });
            setVideoURL(kursBolumu.videoURL);
            setKursID(kursBolumu.kursID);
            setLoading(false);
            console.log("kursID : ", kursID);
        }
    }, [kursBolumu]);

    const onSubmit = (e) => {
        setIsPending(true);
        kursBolumGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    // resim yükleme kısmı
    const beforeUpload = (file) => {
        const isVideo = file.type === "video/x-msvideo" || file.type === "video/mp4" || file.type === "video/quicktime" || file.type === "video/mpeg";
        if (!isVideo) {
            message.error(t("genel.videoOnly"));
        }
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isLt500M) {
            message.error(t("genel.lessThan500"));
        }
        return isVideo && isLt500M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            //setLoading(false);
            console.log("video uploaded");
        }
    };

    const videoUpload = async ({ onError, onSuccess, file }) => {
        setLoading(true);
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("doc", file, `video_${id}.${fileExt}`);
        formData.append("kursBolumID", id);
        const createXHR = () => new XMLHttpRequest();
        fetch(`http://89.19.24.18:3001/KursBolum/${kursID}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                console.log(res, "res");
                setLoading(false);
            });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <KmyHeader
                caller="sayfaBaslik.kursBolumGuncelle"
                buttons={[kursBolumKaydetButon, kursBolumSilButon]}
                backURL={`/kursGuncelle/${kursID}`}
            />
            {contextHolder}
            <Form.Item
                name="kursBolumIsmi"
                label={t("kursBolum.kursBolumIsmi")}
                rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                style={{ paddingTop: 20 }}
            >
                <Input placeholder={t("kursBolum.kursBolumIsmiHint")} />
            </Form.Item>
            <Form.Item
                name="kursBolumIcerik"
                label={t("kursBolum.kursBolumIcerik")}
                rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
            >
                <ReactQuill style={{ backgroundColor: "white", minHeight: 300 }} />
            </Form.Item>
            <Form.Item name="yayinlandiMi" label={t("kursBolum.yayinlandiMi")}>
                <Switch checkedChildren={t("genel.yayinlandi")} unCheckedChildren={t("genel.yayinlanmadi")} />
            </Form.Item>
            <Form.Item name="videoURL" label={t("kursBolum.video")}>
                {videoURL ? (
                    <ReactPlayer url={`${videoURL}`} controls={true} />
                ) : (
                    <Upload
                        name="videoURL"
                        accept=".mp4, .mov, .avi, .wmv"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={videoUpload}
                        beforeUpload={beforeUpload}
                        onChange={handleUploadChange}
                    >
                        <Button
                            type="primary"
                            icon={loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                            title="Upload"
                        >
                            Upload
                        </Button>
                    </Upload>
                )}
            </Form.Item>
        </Form>
    );
}

export default KursBolumGuncelle;
