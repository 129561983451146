import { Form, Input, Layout, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useFirmalariGetir } from "../hooks/useFirma";
import { useRolleriGetir } from "../hooks/useRol";
import { useKisiFirmaYaz } from "../hooks/useKisiFirma";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

const { Content } = Layout;

function KisiFirmaYaz(props) {
    const { t } = useTranslation();
    const [docForm] = Form.useForm();
    const { firmalar } = useFirmalariGetir();
    const { kisiFirmaYaz } = useKisiFirmaYaz();
    const { roller } = useRolleriGetir();
    // const { kisiID, firmaID } = useGetUserInfo();
    const [firmaOptions, setFirmaOptions] = useState([]);
    const [rolOptions, setRolOptions] = useState([]);

    useEffect(() => {
        docForm.setFieldsValue({
            kisiID: props.fieldValues.kisiID,
            firmaID: props.fieldValues.firmaID,
            roller: props.fieldValues.roller,
        });
    }, [props]);

    useEffect(() => {
        if (firmalar) {
            const dropdownItemsFirma = firmalar.map((item) => ({ value: String(item.firmaID), label: String(item.firmaismi) }));
            setFirmaOptions(dropdownItemsFirma);
        }
    }, [firmalar]);

    useEffect(() => {
        if (roller) {
            const dropdownItemsRol = roller.map((item) => ({ value: String(item.rolID), label: String(item.isim) }));
            setRolOptions(dropdownItemsRol);
        }
    }, [roller]);

    const onSubmit = (e) => {
        e.kisiID = props.fieldValues.kisiID;
        console.log("submitted :", e);
        kisiFirmaYaz({ e });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="kisiFirmaYazForm"
                    form={docForm}
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item name="firmaID" label={t("firma.firma")} style={{ marginTop: 20 }}>
                        <Select
                            showSearch
                            placeholder={t("genel.seciniz")}
                            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                        >
                            <Select.Option key="firmaSeciniz" value="0" disabled={true}>
                                {t("genel.seciniz")}
                            </Select.Option>
                            {firmaOptions.map((item, index) => (
                                <Select.Option value={item.value} key={index}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="roller" label={t("rol.roller")} style={{ marginTop: 20 }}>
                        <Select placeholder={t("genel.seciniz")} mode="tags">
                            {rolOptions.map((item, index) => (
                                <Select.Option value={item.value} key={index}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default KisiFirmaYaz;
