import { Button, Form, Input, Layout, List, notification, Space, Spin } from "antd";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetToken } from "../hooks/useGetToken";
import KmyHeader from "./KmyHeader";
const { Content } = Layout;

function UetdsTc() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { accessToken } = useGetToken();
    const [api, contextHolder] = notification.useNotification();
    const [isPending, setIsPending] = useState(false);
    const [sonucKodu, setSonucKodu] = useState();
    const [sonucMesaji, setSonucMesaji] = useState();
    const [belgeListesi, setBelgeListesi] = useState([]);

    const onSubmit = (e) => {
        setIsPending(true);
        setBelgeListesi([]);
        setSonucKodu();
        setSonucMesaji();
        fetch(`http://89.19.24.18:3001/ApiServis/uetdsTc/${e["TCno"]}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("data : ", data);
                if (data) {
                    if (data.sonucKodu == "0") {
                        setBelgeListesi(data.belgeListesi);
                    }
                    setSonucKodu(data.sonucKodu);
                    setSonucMesaji(data.sonucMesaji);
                }
            });
        setIsPending(false);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                >
                    <KmyHeader caller="sayfaBaslik.uetdsTc" backURL="/" />
                    {contextHolder}
                    <Form.Item
                        name="TCno"
                        label={t("kisi.TCno")}
                        style={{ margin: 40 }}
                        required
                        rules={[
                            {
                                validator(_, tc) {
                                    if (tc) {
                                        if (tc.length === 11 && tc[0] !== "0") {
                                            var tek = Number(tc[0]) + Number(tc[2]) + Number(tc[4]) + Number(tc[6]) + Number(tc[8]);
                                            var cift = Number(tc[1]) + Number(tc[3]) + Number(tc[5]) + Number(tc[7]);
                                            var t10 = (tek * 7 - cift) % 10;
                                            if (Number(tc[9]) === t10 && Number(tc[10]) === (cift + tek + t10) % 10) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t("kisi.invalidTckn"));
                                            }
                                        } else {
                                            return Promise.reject(t("kisi.invalidTckn"));
                                        }
                                    } else {
                                        return Promise.reject(t("kisi.invalidTckn"));
                                    }
                                },
                                message: t("kisi.invalidTckn"),
                            },
                        ]}
                    >
                        <Space.Compact style={{ width: "100%" }}>
                            <Input placeholder={t("kisi.TCno")} maxLength={11} type="number" />
                            <Button type="primary" htmlType="submit" loading={isPending}>
                                Kontrol et
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center", color: "#aaa", marginBottom: 20 }}>
                        Bu ekran Ulaştırma Bakanlığının UETDS servisini kullanır. Girilen TC numarasına kayıtlı belgeler varsa listeler.
                    </Space>
                    {sonucKodu ? (
                        <Space
                            direction="horizontal"
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                backgroundColor: sonucKodu == "44" ? "#FF0" : sonucKodu == "0" ? "#8F8" : "#F00",
                                padding: 10,
                            }}
                        >
                            {sonucMesaji}
                        </Space>
                    ) : null}
                    {belgeListesi.length > 0 ? (
                        <List
                            bordered
                            dataSource={belgeListesi}
                            style={{ marginLeft: 50, marginRight: 50, marginBottom: 20 }}
                            renderItem={(item) => (
                                <List.Item key={item}>
                                    <Space style={{ width: "100%", justifyContent: "space-between" }}>{item}</Space>
                                </List.Item>
                            )}
                        />
                    ) : null}
                </Form>
            </Content>
        </Layout>
    );
}

export default UetdsTc;
