import { Form, Button, DatePicker, Input, Layout, notification, Popconfirm, Radio, Select, Spin, Tabs, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useTespitiGetir, useTespitGuncelle, useTespitSil, useOgrencileriGetir } from "../hooks/useTespit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Content } = Layout;

function TespitGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { tespit } = useTespitiGetir(id);
    const { tespitGuncelle } = useTespitGuncelle(id);
    const { tespitSil } = useTespitSil(id);

    const tespitKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="tespitKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const tespitSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="tespitSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                tespitSil(id);
                navigate("/tespitList");
            }}
            style={{ width: 400 }}
        >
            <Button key="tespitSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (tespit) {
            form.setFieldsValue({
                konu: tespit.konu,
                tespit: tespit.tespit,
                tavsiye: tespit.tavsiye,
            });
            setLoading(false);
        }
    }, [tespit]);

    const onSubmit = (e) => {
        setIsPending(true);
        console.log("form bilgisi : ", e);
        tespitGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="tespitGuncelleKmyHeader"
                        caller="sayfaBaslik.tespitGuncelle"
                        buttons={[tespitKaydetButon, tespitSilButon]}
                        backURL="/tespitList"
                    />
                    {contextHolder}
                    <Form.Item name="konu" label={t("tespit.konu")}>
                        <Input placeholder={t("tespit.konuHint")} />
                    </Form.Item>
                    <Form.Item name="tespit" label={t("tespit.tespit")}>
                        <ReactQuill placeholder={t("tespit.tespitHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                    <Form.Item name="tavsiye" label={t("tespit.tavsiye")}>
                        <ReactQuill placeholder={t("tespit.tavsiyeHint")} style={{ backgroundColor: "white" }} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default TespitGuncelle;
