import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useEnvanterEkle = () => {
    const { accessToken } = useGetToken();
    const envanterEkle = async (e) => {
        fetch(`http://89.19.24.18:3001/Envanter/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("envanter add cevap : ", data);
            });
    };

    return { envanterEkle };
};

export const useEnvanterGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const envanterGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Envanter/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("envanter update cevap : ", data);
            });
    };
    return { envanterGuncelle };
};

export const useEnvanterSil = (id) => {
    const { accessToken } = useGetToken();
    const envanterSil = async () => {
        fetch(`http://89.19.24.18:3001/Envanter/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("envanter delete cevap : ", data);
            });
    };
    return { envanterSil };
};

export const useEnvanterleriGetir = () => {
    const { accessToken } = useGetToken();
    const [envanterler, setEnvanterler] = useState([]);
    const envanterleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Envanter", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEnvanterler(data);
            });

        return () => envanterler;
    };
    useEffect(() => {
        envanterleriGetir();
    }, []);

    return { envanterler };
};

export const useEnvanteriGetir = (id) => {
    const { accessToken } = useGetToken();
    const [envanter, setEnvanter] = useState([]);
    const envanteriGetir = async () => {
        fetch(`http://89.19.24.18:3001/Envanter/byId/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEnvanter(data);
            });

        return () => envanter;
    };

    useEffect(() => {
        envanteriGetir();
    }, []);

    return { envanter };
};
