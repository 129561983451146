import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import meslekiYeterlilik from "../constant/meslekiYeterlilik.json";
import SGKgorevKodu from "../constant/SGKgorevKodu";
import ImgCrop from "antd-img-crop";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import {
    Alert,
    Avatar,
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    Layout,
    List,
    message,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Tabs,
    Tag,
    Tooltip,
    Upload,
} from "antd";
import {
    CalendarOutlined,
    CheckSquareFilled,
    EditOutlined,
    EyeOutlined,
    LoadingOutlined,
    MailOutlined,
    PhoneOutlined,
    PlusOutlined,
    LikeFilled,
    DislikeFilled,
    ClockCircleFilled,
    IdcardOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { useKisiyiGetir, useKisiGuncelle, useKisiyiSil, useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useKisiFirmaGetir } from "../hooks/useKisiFirma";
import { useBelgeTipleriniGetir } from "../hooks/useBelge";
import KmyHeader from "./KmyHeader";
import BelgeGuncelle from "./BelgeGuncelle";
import BelgeEkle from "./BelgeEkle";
import KisiFirmaYaz from "./KisiFirmaYaz";
import BolumSelect from "../components/BolumSelect";
import SahaDegerleri from "../components/SahaDegerleri";
import { useFirmalariGetir } from "../hooks/useFirma";
import KisiKimlik from "./KisiKimlik";
import { PDFDownloadLink } from "@react-pdf/renderer";

const { Header, Content } = Layout;

function KisiGuncelle() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { id } = useParams();
    const [docUpdateModalOpen, setDocUpdateModalOpen] = useState(false);
    const [docModalOpen, setDocModalOpen] = useState(false);
    const [kisiFirmaModalOpen, setKisiFirmaModalOpen] = useState(false);
    const { firmalar } = useFirmalariGetir();
    const [firmaOptions, setFirmaOptions] = useState([]);

    const { kisi } = useKisiyiGetir(id);
    const { kisiFirma } = useKisiFirmaGetir(id);
    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const { kisiGuncelle } = useKisiGuncelle(id);
    const { kisiyiSil } = useKisiyiSil(id);
    const [SGKgorevKoduDataSource, setSGKgorevKoduDataSource] = useState([]);
    const [filteredgorevlerource, setFilteredgorevlerource] = useState([]);
    const [filteredDocSource, setFilteredDocSource] = useState([]);
    const [docTypeList, setDocTypeList] = useState([]);
    const { belgeTipleri } = useBelgeTipleriniGetir(id, "kisi");

    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const [avatarURL, setAvatarURL] = useState();
    const [docsDataSource, setDocsDataSource] = useState([]);
    const [kisiFirmaDataSource, setKisiFirmaDataSource] = useState([]);
    const [belgeler, setBelgeler] = useState();
    const [docProps, setDocProps] = useState();
    const [yetkiler, setYetkiler] = useState([]);
    const [adSoyad, setAdSoyad] = useState();

    const [user, setUser] = useState({});

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        belgeleriGetir();
    }, []);

    useEffect(() => {
        setDocTypeList(belgeTipleri);
    }, [belgeTipleri]);

    useEffect(() => {
        setYetkiler(kisininYetkileri);
    }, [kisininYetkileri]);

    useEffect(() => {
        setTimeout(function () {
            belgeleriGetir();
        }, 1000);
    }, [docModalOpen, docUpdateModalOpen]);

    useEffect(() => {
        setDocsDataSource(belgeler);
        setFilteredDocSource(belgeler);
        setIsPending(false);
    }, [belgeler]);

    useEffect(() => {
        setSGKgorevKoduDataSource(SGKgorevKodu);
        setFilteredgorevlerource(SGKgorevKodu);
    }, [SGKgorevKodu]);

    useEffect(() => {
        if (firmalar) {
            const dropdownItemsFirma = firmalar.map((item) => ({ value: String(item.firmaID), label: String(item.firmaismi) }));
            setFirmaOptions(dropdownItemsFirma);
        }
    }, [firmalar]);

    const belgeleriGetir = async () => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        let unsubscribe;
        fetch(`http://89.19.24.18:3001/Belge/${id}/kisi`, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setBelgeler(data);
            });

        setBelgeler(unsubscribe);
    };

    const onSearch = (searchText) => {
        setFilteredgorevlerource(SGKgorevKoduDataSource);
        if (searchText) {
            setFilteredgorevlerource(
                SGKgorevKoduDataSource.filter((item) => {
                    const codeName = item.label ? item.label.toLowerCase() : "";
                    return codeName.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const beforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isLt10M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            console.log("image upload done");
        }
    };

    const avatarUpload = async ({ onError, onSuccess, file }) => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("doc", file, `avatar.${fileExt}`);
        const createXHR = () => new XMLHttpRequest();
        console.log("formdata : ", formData);
        fetch(`http://89.19.24.18:3001/Kisi/${id}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                console.log("cevap geldi : ", response);
                return response.json();
            })
            .then((res) => {
                console.log("avatar atandı : ", res.body);
                setAvatarURL(res.body);
            });
        setLoading(false);
    };

    function birKisiSil() {
        kisiyiSil(id);
        navigate("/kisiList");
    }

    useEffect(() => {
        if (kisiFirma) {
            setKisiFirmaDataSource(kisiFirma);
        }
    }, [kisiFirma]);

    const clickHandler = (key) => {
        navigate(`/kisiKimlik/${id}`); //<KisiKimlik avatarURL={avatarURL} isim={`${kisi.isim} ${kisi.soyad}`} />;
    };

    const kimlikButton = (
        <Button type="link" style={{ paddingTop: 10 }} fileName="kisiKimlik.pdf" onClick={clickHandler}>
            <IdcardOutlined style={{ color: "white", fontSize: "20pt" }} />
        </Button>
    );

    const savePersonButton = (yetkiler.includes("ADMIN") || yetkiler.includes("KISIKY") || yetkiler.includes("KISI")) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    const deletePersonButton =
        yetkiler.includes("ADMIN") || yetkiler.includes("KISIKS") || yetkiler.includes("KISI") ? (
            <Popconfirm
                placement="topRight"
                title={t("genel.eminMisin")}
                description={t("genel.silinecek")}
                okText={t("genel.sil")}
                cancelText={t("genel.vazgec")}
                onConfirm={() => {
                    birKisiSil();
                }}
                style={{ width: 400 }}
            >
                <Button type="primary" danger size="small">
                    {t("genel.sil")}
                </Button>
            </Popconfirm>
        ) : null;

    useEffect(() => {
        if (kisi) {
            const checkThat = kisi.email;
            if (checkThat) {
                form.setFieldsValue({
                    email: kisi.email,
                    firmaID: kisi.firmaID,
                    telefonNo: kisi.telefonNo,
                    TCno: kisi.TCno,
                    isim: kisi.isim,
                    soyad: kisi.soyad,
                    SGKgorevKodu: kisi.SGKgorevKodu,
                    unvan: kisi.unvan,
                    meslekiYeterlilikKodu: kisi.meslekiYeterlilikKodu,
                    dogumYili: kisi.dogumYili,
                    cinsiyet: kisi.cinsiyet,
                    kanGurubu: kisi.kanGurubu,
                    evTelefonu: kisi.evTelefonu,
                    acilDurumEkibi: kisi.acilDurumEkibi,
                    acilDurumKisi1: kisi.acilDurumKisi1,
                    acilDurumYakinlik1: kisi.acilDurumYakinlik1,
                    acilDurumTelefon1: kisi.acilDurumTelefon1,
                    acilDurumKisi2: kisi.acilDurumKisi2,
                    acilDurumYakinlik2: kisi.acilDurumYakinlik2,
                    acilDurumTelefon2: kisi.acilDurumTelefon2,
                    firmadakiUsername: kisi.firmadakiUsername,
                    firmadakiSicilno: kisi.firmadakiSicilno,
                    dahiliNumara: kisi.dahiliNumara,
                    meslekiYeterlilik: kisi.meslekiYeterlilik,
                    egitimDurumu: kisi.egitimDurumu,
                    okul: kisi.okul,
                    gorevler: kisi.gorevler,
                    bolumID: kisi.bolumID,
                    sorumluluk: kisi.sorumluluk ? kisi.sorumluluk : null,
                    girisTarihi: kisi.girisTarihi ? dayjs(kisi.girisTarihi) : null,
                    cikisTarihi: kisi.cikisTarihi ? dayjs(kisi.cikisTarihi) : null,
                });
                setAdSoyad(`${kisi.isim} ${kisi.soyad}`);
                setLoading(false);
                setAvatarURL(kisi.fotoURL);
            }
        }
    }, [kisi]);

    useEffect(() => {}, [avatarURL]);

    const onSubmit = (e) => {
        setIsPending(true);
        kisiGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    const headerButtons = [kimlikButton, savePersonButton, deletePersonButton];

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes("ADMIN") && !yetkiler.includes("KISIKY") && !yetkiler.includes("KISI")}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 11 }}
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={(error) => {
                        console.error("onFinishFailed : " + { error });
                    }}
                >
                    <KmyHeader caller={adSoyad} buttons={headerButtons} backURL="/kisiList" />

                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", marginBottom: 20, justifyContent: "center" }}>
                        <ImgCrop
                            showGrid="true"
                            rotationSlider="true"
                            modalTitle="Resmi düzenle"
                            modalCancel={t("genel.vazgec")}
                            modalOk={t("genel.kaydet")}
                            cropShape="round"
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg, .jpeg, .png"
                                listType="picture-circle"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={avatarUpload}
                                beforeUpload={beforeUpload}
                                onChange={handleUploadChange}
                            >
                                {avatarURL ? (
                                    <Avatar src={`${avatarURL}`} alt="avatar" style={{ width: "100%", height: "100%" }} />
                                ) : (
                                    <button style={{ border: 1, background: "none" }} type="button">
                                        {loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                                        <div style={{ marginTop: 8, marginBottom: -20, cursor: "pointer" }}>Upload</div>
                                    </button>
                                )}
                            </Upload>
                        </ImgCrop>
                    </Space>
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "1",
                                label: t("kisi.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item name="email" label={t("kisi.email")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                                            <Input
                                                prefix={<MailOutlined />}
                                                placeholder={t("kisi.email")}
                                                disabled={true}
                                                suffix={
                                                    kisi.nviOnayladi ? (
                                                        <Tooltip title={t("kisi.emailDogrulandi")}>
                                                            <CheckSquareFilled style={{ fontSize: "20px", color: "#0A0" }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item name="firmaID" label={t("kisi.firmaIsmi")} style={{ marginTop: 20 }}>
                                            <Select
                                                showSearch
                                                placeholder={t("genel.seciniz")}
                                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {firmaOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name="telefonNo" label={t("kisi.telefonNo")}>
                                            <PhoneInput defaultCountry={"TR"} placeholder={t("kisi.telefonNoHint")} inputComponent={Input} />
                                        </Form.Item>
                                        <Form.Item
                                            name="TCno"
                                            label={t("kisi.TCno")}
                                            rules={[
                                                {
                                                    warningOnly: true,
                                                    validator(_, tc) {
                                                        if (tc) {
                                                            if (tc.length === 11 && tc[0] !== "0") {
                                                                var tek =
                                                                    Number(tc[0]) + Number(tc[2]) + Number(tc[4]) + Number(tc[6]) + Number(tc[8]);
                                                                var cift = Number(tc[1]) + Number(tc[3]) + Number(tc[5]) + Number(tc[7]);
                                                                var t10 = (tek * 7 - cift) % 10;
                                                                if (Number(tc[9]) === t10 && Number(tc[10]) === (cift + tek + t10) % 10) {
                                                                    return Promise.resolve();
                                                                } else {
                                                                    return Promise.reject(t("kisi.invalidTckn"));
                                                                }
                                                            } else {
                                                                return Promise.reject(t("kisi.invalidTckn"));
                                                            }
                                                        } else {
                                                            return Promise.reject(t("kisi.invalidTckn"));
                                                        }
                                                    },
                                                    message: t("kisi.invalidTckn"),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={t("kisi.TCno")}
                                                maxLength={11}
                                                suffix={
                                                    kisi.nviOnayladi ? (
                                                        <Tooltip title={t("kisi.nviOnayladi")}>
                                                            <CheckSquareFilled style={{ fontSize: "20px", color: "#0A0" }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                                type="number"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="isim"
                                            label={t("kisi.isim")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <Input
                                                placeholder={t("kisi.isim")}
                                                onInput={(e) => {
                                                    var result = "";
                                                    var words = e.target.value.split(" ");
                                                    words.forEach(function (value, index, array) {
                                                        value = value.charAt(0).toUpperCase() + value.slice(1);
                                                        result = result.trimStart() + " " + value;
                                                    });
                                                    // const reducedArray = words.reduce((acc, curr) => `${acc}${curr.lat},${curr.lon}|` ,'')
                                                    e.target.value = result.trimStart();
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="soyad"
                                            label={t("kisi.soyad")}
                                            rules={[
                                                { required: true, message: t("genel.gerekli") },
                                                { whitespace: true },
                                                {
                                                    transform: (v) => {
                                                        v = v.toUpperCase();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t("kisi.soyad")} onInput={(e) => (e.target.value = e.target.value.toUpperCase())} />
                                        </Form.Item>
                                        <BolumSelect name="bolumID" label={t("kisi.bolum")} hint={t("kisi.bolumHint")} />

                                        <SahaDegerleri name="unvan" id="kisi.unvan" label={t("kisi.unvan")} />

                                        <Form.Item name="dogumYili" label={t("kisi.dogumYili")}>
                                            <Input
                                                prefix={<CalendarOutlined />}
                                                placeholder={t("kisi.dogumYili")}
                                                maxLength={4}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="cinsiyet" label={t("kisi.cinsiyet")}>
                                            <Select placeholder={t("kisi.cinsiyet")}>
                                                <Select.Option value="E">{t("kisi.erkek")}</Select.Option>
                                                <Select.Option value="K">{t("kisi.kadin")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <SahaDegerleri mode="tags" name="gorevler" id="kisi.gorev" label={t("kisi.gorev")} />
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("kisi.tabAcil"),
                                children: (
                                    <>
                                        <Form.Item name="kanGurubu" label={t("kisi.kanGurubu")} requiredMark="optional">
                                            <Select placeholder={t("kisi.kanGurubu")}>
                                                <Select.Option value="0-">0-</Select.Option>
                                                <Select.Option value="0+">0+</Select.Option>
                                                <Select.Option value="A-">A-</Select.Option>
                                                <Select.Option value="A+">A+</Select.Option>
                                                <Select.Option value="B-">B-</Select.Option>
                                                <Select.Option value="B+">B+</Select.Option>
                                                <Select.Option value="AB-">AB-</Select.Option>
                                                <Select.Option value="AB+">AB+</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="evTelefonu" label={t("kisi.evTelefonu")}>
                                            <PhoneInput defaultCountry={"TR"} inputComponent={Input} placeholder={t("kisi.telefonNoHint")} />
                                        </Form.Item>
                                        <SahaDegerleri
                                            name="acilDurumEkibi"
                                            id="kisi.acilDurumEkibi"
                                            label={t("kisi.acilDurumEkibi")}
                                            hint={t("kisi.acilDurumEkibiHint")}
                                        />

                                        <Form.Item name="acilDurumKisi1" label={t("kisi.acilDurumKisi1")}>
                                            <Input placeholder={t("kisi.acilDurumKisiHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumYakinlik1" label={t("kisi.acilDurumYakinlik")}>
                                            <Input placeholder={t("kisi.acilDurumYakinlikHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumTelefon1" label={t("kisi.telefonNo")}>
                                            <PhoneInput defaultCountry={"TR"} inputComponent={Input} placeholder={t("kisi.telefonNoHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumKisi2" label={t("kisi.acilDurumKisi2")}>
                                            <Input placeholder={t("kisi.acilDurumKisiHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumYakinlik2" label={t("kisi.acilDurumYakinlik")}>
                                            <Input placeholder={t("kisi.acilDurumYakinlikHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumTelefon2" label={t("kisi.telefonNo")}>
                                            <PhoneInput defaultCountry={"TR"} inputComponent={Input} placeholder={t("kisi.telefonNoHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("kisi.tabOzluk"),
                                children: (
                                    <>
                                        <Form.Item name="meslekiYeterlilikKodu" label={t("kisi.meslekiYeterlilikKodu")}>
                                            <Cascader options={meslekiYeterlilik} placeholder={t("genel.seciniz")} />
                                        </Form.Item>
                                        <Form.Item name="SGKgorevKodu" label={t("kisi.SGKgorevKodu")} requiredMark="optional">
                                            <Select
                                                placeholder={t("genel.seciniz")}
                                                showSearch
                                                optionFilterProp="label"
                                                onSearch={onSearch}
                                                options={filteredgorevlerource}
                                            ></Select>
                                        </Form.Item>
                                        <BolumSelect mode="tags" name="sorumluluk" label={t("kisi.sorumluluk")} hint={t("kisi.sorumlulukHint")} />
                                        <Form.Item name="firmadakiUsername" label={t("kisi.firmadakiUsername")}>
                                            <Input placeholder={t("kisi.firmadakiUsernameHint")} />
                                        </Form.Item>
                                        <Form.Item name="firmadakiSicilno" label={t("kisi.firmadakiSicilno")}>
                                            <Input placeholder={t("kisi.firmadakiSicilnoHint")} />
                                        </Form.Item>
                                        <Form.Item name="dahiliNumara" label={t("kisi.dahiliNumara")}>
                                            <Input prefix={<PhoneOutlined />} placeholder={t("kisi.dahiliNumaraHint")} />
                                        </Form.Item>
                                        <Form.Item
                                            name="egitimDurumu"
                                            label={t("kisi.egitimDurumu")}
                                            requiredMark="optional"
                                            style={{ textAlign: "left" }}
                                        >
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="0">{t("kisi.egitimDurumu0")}</Select.Option>
                                                <Select.Option value="1">{t("kisi.egitimDurumu1")}</Select.Option>
                                                <Select.Option value="2">{t("kisi.egitimDurumu2")}</Select.Option>
                                                <Select.Option value="3">{t("kisi.egitimDurumu3")}</Select.Option>
                                                <Select.Option value="4">{t("kisi.egitimDurumu4")}</Select.Option>
                                                <Select.Option value="5">{t("kisi.egitimDurumu5")}</Select.Option>
                                                <Select.Option value="6">{t("kisi.egitimDurumu6")}</Select.Option>
                                                <Select.Option value="7">{t("kisi.egitimDurumu7")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="okul" label={t("kisi.okul")}>
                                            <Input placeholder={t("kisi.okulHint")} />
                                        </Form.Item>
                                        <Form.Item name="girisTarihi" label={t("kisi.girisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("kisi.girisTarihi")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="cikisTarihi" label={t("kisi.cikisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("kisi.cikisTarihi")}
                                            />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "4",
                                label: t("kisi.tabBelge"),
                                children: (
                                    <List
                                        style={{ margin: 20 }}
                                        header={
                                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        setDocModalOpen(true);
                                                    }}
                                                >
                                                    {t("genel.belgeEkle")}
                                                </Button>
                                            </Space>
                                        }
                                        bordered
                                        dataSource={filteredDocSource}
                                        renderItem={(item) => (
                                            <List.Item key={item.id}>
                                                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                                    <Space>
                                                        {yetkiler.includes("KISIBG") ? (
                                                            <Button
                                                                type="link"
                                                                icon={<EyeOutlined />}
                                                                style={{ color: "black", marginRight: -10 }}
                                                                onClick={() => {
                                                                    window.open(item.belgeURL, "_blank");
                                                                }}
                                                            />
                                                        ) : null}
                                                        <Button
                                                            type="text"
                                                            icon={<EditOutlined />}
                                                            onClick={() => {
                                                                console.log("clicked : ", item);
                                                                setDocProps({
                                                                    id: item.id,
                                                                    belgeTipiID: item.belgeTipiID,
                                                                    belgeIsmi: item.belgeIsmi,
                                                                    durumAciklamasi: item.durumAciklamasi,
                                                                    verilisTarihi: item.verilisTarihi,
                                                                    gecerlikTarihi: item.gecerlikTarihi,
                                                                });
                                                                setDocUpdateModalOpen(true);
                                                            }}
                                                        />
                                                        {item.belgeIsmi}
                                                    </Space>
                                                    <Tooltip
                                                        title={
                                                            item.belgeDurumu === "hayir" ? item.durumAciklamasi : t(`belgeDurumu.${item.belgeDurumu}`)
                                                        }
                                                    >
                                                        {item.belgeDurumu === "yeni" ? (
                                                            <ClockCircleFilled style={{ color: "#aaa" }} />
                                                        ) : item.belgeDurumu === "hayir" ? (
                                                            <DislikeFilled style={{ color: "red" }} />
                                                        ) : (
                                                            <LikeFilled style={{ color: "green" }} />
                                                        )}
                                                    </Tooltip>
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                ),
                            },
                            {
                                key: "5",
                                label: t("kisi.tabYetki"),
                                children: (
                                    <List
                                        style={{ margin: 20 }}
                                        header={
                                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        setDocProps({
                                                            kisiID: id,
                                                            firmaID: null,
                                                            roller: null,
                                                        });
                                                        setKisiFirmaModalOpen(true);
                                                    }}
                                                >
                                                    {t("kisi.firmaYetkiEkle")}
                                                </Button>
                                            </Space>
                                        }
                                        bordered
                                        dataSource={kisiFirmaDataSource}
                                        renderItem={(item) => (
                                            <List.Item key={item.firmaID}>
                                                <Space style={{ width: "100%" }}>
                                                    <Button
                                                        type="text"
                                                        icon={<EditOutlined />}
                                                        onClick={() => {
                                                            console.log("clicked : ", item);
                                                            setDocProps({
                                                                kisiID: item.kisiID,
                                                                firmaID: item.firmaID,
                                                                roller: item.roller,
                                                            });
                                                            setKisiFirmaModalOpen(true);
                                                        }}
                                                    />
                                                    {item.firmaismi}
                                                    {item.rolAdlari.map((item, idx) => {
                                                        return (
                                                            <Tag color="green" style={{ marginRight: 0 }}>
                                                                {item}
                                                            </Tag>
                                                        );
                                                    })}
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                ),
                            },
                        ]}
                    />
                    {errMessage && (
                        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                            <Alert type="error" message={t(errMessage)} />
                        </Space>
                    )}
                    <Modal
                        key="docModalForm"
                        open={docModalOpen}
                        onOk={() => {
                            setDocModalOpen(false);
                        }}
                        onCancel={() => {
                            setDocModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setDocModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="docAddForm"
                                htmlType="submit"
                                onClick={() => {
                                    setDocModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <BelgeEkle owner="kisi" id={id} typeList={docTypeList} />
                    </Modal>
                    <Modal
                        key="docUpdateModalForm"
                        open={docUpdateModalOpen}
                        onOk={() => {
                            setDocUpdateModalOpen(false);
                        }}
                        onCancel={() => {
                            setDocUpdateModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setDocUpdateModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="docUpdateForm"
                                htmlType="submit"
                                onClick={() => {
                                    setDocUpdateModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <BelgeGuncelle fieldValues={docProps} id={id} typeList={docTypeList} />
                    </Modal>

                    <Modal
                        key="kisiFirmaYazForm"
                        open={kisiFirmaModalOpen}
                        onOk={() => {
                            setKisiFirmaModalOpen(false);
                        }}
                        onCancel={() => {
                            setKisiFirmaModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setKisiFirmaModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="kisiFirmaYazForm"
                                htmlType="submit"
                                onClick={() => {
                                    setKisiFirmaModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <KisiFirmaYaz fieldValues={docProps} id={id} />
                    </Modal>
                </Form>
            </Content>
        </Layout>
    );
}

export default KisiGuncelle;
