import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useTatbikatEkle = () => {
    const { accessToken } = useGetToken();
    const tatbikatEkle = async (e) => {
        fetch(`http://89.19.24.18:3001/Tatbikat/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tatbikat add cevap : ", data);
            });
    };

    return { tatbikatEkle };
};

export const useTatbikatGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const tatbikatGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Tatbikat/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tatbikat update cevap : ", data);
            });
    };
    return { tatbikatGuncelle };
};

export const useTatbikatSil = (id) => {
    const { accessToken } = useGetToken();
    const tatbikatSil = async () => {
        fetch(`http://89.19.24.18:3001/Tatbikat/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tatbikat delete cevap : ", data);
            });
    };
    return { tatbikatSil };
};

export const useTatbikatlariGetir = () => {
    const { accessToken } = useGetToken();
    const [tatbikatlar, setTatbikatlar] = useState([]);
    const tatbikatlariGetir = async () => {
        fetch("http://89.19.24.18:3001/Tatbikat", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setTatbikatlar(data);
            });

        return () => tatbikatlar;
    };
    useEffect(() => {
        tatbikatlariGetir();
    }, []);

    return { tatbikatlar };
};

export const useTatbikatiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [tatbikat, setTatbikat] = useState([]);
    const tatbikatiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Tatbikat/byId/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setTatbikat(data);
            });

        return () => tatbikat;
    };

    useEffect(() => {
        tatbikatiGetir();
    }, []);

    return { tatbikat };
};
