import { Document, Font, Image, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import { PDFViewer } from "@react-pdf/renderer";
import { QRCodeSVG } from "qrcode.react";
import Karekod from "./Karekod";
import { useKisiyiGetir } from "../hooks/useKisi";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFFFFF",
    },

    arkaPlan: { position: "absolute", right: 0, bottom: 10, width: 100, height: 100, zIndex: 0, opacity: 0.05 },
    foto: { position: "absolute", left: 20, top: 20, width: 100, height: 100, zIndex: 1, backgroundColor: "#fecb2e" },
    logo: { position: "absolute", right: 20, top: 20, width: 210, height: 70, zIndex: 1, backgroundColor: "#fecb2e" },
    karekod: { position: "absolute", left: 20, bottom: 20, width: 100, height: 100, zIndex: 1 },
    firma: {
        position: "absolute",
        top: 100,
        left: 140,
        right: 0,
        height: 50,
        color: "#005",
        fontFamily: "Roboto",
        fontSize: 14,
        overflow: "ellipsis",
    },
    isim: { position: "absolute", top: 180, left: 140, right: 10, height: 50, color: "#005", fontFamily: "Roboto", fontSize: 20 },
    gorev: { position: "absolute", top: 210, left: 140, right: 10, height: 50, color: "#005", fontFamily: "Roboto", fontSize: 20 },
    kan: { position: "absolute", top: 240, left: 140, right: 10, height: 50, color: "#005", fontFamily: "Roboto", fontSize: 24 },
});

const KisiKimlik = (props) => {
    let { id } = useParams();
    const { kisi } = useKisiyiGetir(id);
    const [avatarURL, setAvatarURL] = useState();
    const [adSoyad, setAdSoyad] = useState();

    useEffect(() => {
        if (kisi) {
            const checkThat = kisi.email;
            if (checkThat) {
                setAdSoyad(`${kisi.isim} ${kisi.soyad}`);
                setAvatarURL(kisi.fotoURL);
            }
        }
    }, [kisi]);

    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
                <Page size="A6" style={styles.page} orientation="landscape">
                    <View style={styles.arkaPlan}>
                        <Image src="https://pro.kmysistem.com/images/gvnLogo.png" />
                    </View>
                    <View style={styles.foto}>
                        <Image src={avatarURL ? avatarURL : "https://pro.kmysistem.com/dosya/56.jpg"} crossOrigin="anonymous" />
                    </View>
                    <View style={styles.logo}>
                        <Image src="https://pro.kmysistem.com/dosya/56.jpg" />
                    </View>
                    <View style={styles.karekod}>
                        <Karekod url="http://app.kmysistem.com/" width={100} level={0} />
                    </View>
                    <View style={styles.firma}>
                        <Text>Firma İsmi Firma İsmi Firma İsmi Firma İsmi Firma İsmi Firma İsmi Firma İsmi Firma İsmi</Text>
                    </View>
                    <View style={styles.isim}>
                        <Text>{adSoyad}</Text>
                    </View>
                    <View style={styles.gorev}>
                        <Text>{kisi ? kisi.unvan : ""}</Text>
                    </View>
                    <View style={styles.kan}>
                        <Text>{kisi ? kisi.kanGurubu : ""}</Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};
export default KisiKimlik;
