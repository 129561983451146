import ekipmanTipleri from "../constant/ekipmanTipleri.json";
import { Button, Cascader, DatePicker, Form, Input, Layout, notification, Popconfirm, Spin } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useEkipmaniGetir, useEkipmanGuncelle, useEkipmanSil } from "../hooks/useEkipman";
import "react-quill/dist/quill.snow.css";
import SahaDegerleri from "../components/SahaDegerleri";

const { Content } = Layout;

function EkipmanGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { ekipman } = useEkipmaniGetir(id);
    const { ekipmanGuncelle } = useEkipmanGuncelle(id);
    const { ekipmanSil } = useEkipmanSil(id);

    const ekipmanKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="ekipmanKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const ekipmanSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="ekipmanSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                ekipmanSil(id);
                navigate("/ekipmanList");
            }}
            style={{ width: 400 }}
        >
            <Button key="ekipmanSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        /*  
            ekipmanID: { type: DataTypes.STRING(7), primaryKey: true },
            ekipmanTipiID: { type: DataTypes.STRING(7), allowNull: true },
            firmaID: { type: DataTypes.STRING(6), allowNull: true },
            bolumID: { type: DataTypes.INTEGER, allowNull: true },
            bolgeID: { type: DataTypes.STRING(6), allowNull: true },
            uretimTarihi: { type: DataTypes.DATE, allowNull: true },
            garantiTarihi: { type: DataTypes.DATE, allowNull: true },
 */
        if (ekipman) {
            form.setFieldsValue({
                isim: ekipman.isim,
                ekipmanTipi: ekipman.ekipmanTipi,
                demirbasNo: ekipman.demirbasNo,
                marka: ekipman.marka,
                model: ekipman.model,
                serino: ekipman.serino,
                konum: ekipman.konum,
                uretici: ekipman.uretici,
                iso: ekipman.iso,
                tse: ekipman.tse,
                atex: ekipman.atex,
                ce: ekipman.ce,
                digerStandart: ekipman.digerStandart,
            });
            setLoading(false);
        }
    }, [ekipman]);

    const onSubmit = (e) => {
        setIsPending(true);
        console.log("form bilgisi : ", e);
        ekipmanGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="ekipmanGuncelleKmyHeader"
                        caller="sayfaBaslik.ekipmanGuncelle"
                        buttons={[ekipmanKaydetButon, ekipmanSilButon]}
                        backURL="/ekipmanList"
                    />
                    {contextHolder}
                    <Form.Item name="isim" label={t("ekipman.isim")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Input placeholder={t("ekipman.isimHint")} />
                    </Form.Item>
                    <Form.Item name="ekipmanTipi" label={t("ekipman.ekipmanTip")}>
                        <Cascader
                            options={ekipmanTipleri}
                            placeholder={t("genel.seciniz")}
                            optionRender={(option) => {
                                return t(`ekipmanTip.${option.value}`);
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="demirbasNo" label={t("ekipman.demirbasNo")}>
                        <Input placeholder={t("ekipman.demirbasNoHint")} />
                    </Form.Item>
                    <SahaDegerleri name="marka" id="ekipman.marka" label={t("ekipman.marka")} hint={t("ekipman.markaHint")} />
                    <SahaDegerleri name="model" id="ekipman.model" label={t("ekipman.model")} hint={t("ekipman.modelHint")} />
                    <Form.Item name="serino" label={t("ekipman.serino")}>
                        <Input placeholder={t("ekipman.serinoHint")} />
                    </Form.Item>
                    <SahaDegerleri name="konum" id="ekipman.konum" label={t("ekipman.konum")} hint={t("ekipman.konumHint")} />
                    <SahaDegerleri name="uretici" id="ekipman.uretici" label={t("ekipman.uretici")} hint={t("ekipman.ureticiHint")} />
                    <Form.Item name="iso" label={t("ekipman.iso")}>
                        <Input placeholder={t("ekipman.isoHint")} />
                    </Form.Item>
                    <Form.Item name="tse" label={t("ekipman.tse")}>
                        <Input placeholder={t("ekipman.tseHint")} />
                    </Form.Item>
                    <Form.Item name="atex" label={t("ekipman.atex")}>
                        <Input placeholder={t("ekipman.atexHint")} />
                    </Form.Item>
                    <Form.Item name="ce" label={t("ekipman.ce")}>
                        <Input placeholder={t("ekipman.ceHint")} />
                    </Form.Item>
                    <Form.Item name="digerStandart" label={t("ekipman.digerStandart")}>
                        <Input placeholder={t("ekipman.digerStandartHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default EkipmanGuncelle;
