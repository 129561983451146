import { Form, ConfigProvider, Select, Tooltip } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";

function AdrListesi(props) {
    const { t } = useTranslation();

    return (
        <ConfigProvider theme={{ components: { Select: { multipleItemHeight: 50 } } }}>
            <Form.Item name={props.name} label={props.label}>
                <Select placeholder={t("genel.seciniz")} mode="tags" style={{ height: 60, padding: 0, margin: 0 }}>
                    <Select.Option value="10">
                        <img src="https://pro.kmysistem.com/images/adr10.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="12">
                        <img src="https://pro.kmysistem.com/images/adr12.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="13">
                        <img src="https://pro.kmysistem.com/images/adr13.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="14">
                        <img src="https://pro.kmysistem.com/images/adr14.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="15">
                        <img src="https://pro.kmysistem.com/images/adr15.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="16">
                        <img src="https://pro.kmysistem.com/images/adr16.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="21">
                        <img src="https://pro.kmysistem.com/images/adr21.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="22">
                        <img src="https://pro.kmysistem.com/images/adr22.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="23">
                        <img src="https://pro.kmysistem.com/images/adr23.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="30">
                        <img src="https://pro.kmysistem.com/images/adr30.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="41">
                        <img src="https://pro.kmysistem.com/images/adr41.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="42">
                        <img src="https://pro.kmysistem.com/images/adr42.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="43">
                        <img src="https://pro.kmysistem.com/images/adr43.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="51">
                        <img src="https://pro.kmysistem.com/images/adr51.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="52">
                        <img src="https://pro.kmysistem.com/images/adr52.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="61">
                        <img src="https://pro.kmysistem.com/images/adr61.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="62">
                        <img src="https://pro.kmysistem.com/images/adr62.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="7A">
                        <img src="https://pro.kmysistem.com/images/adr7A.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="7B">
                        <img src="https://pro.kmysistem.com/images/adr7B.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="7C">
                        <img src="https://pro.kmysistem.com/images/adr7C.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="7D">
                        <img src="https://pro.kmysistem.com/images/adr7D.png" style={{ width: 50 }} />
                    </Select.Option>
                </Select>
            </Form.Item>
        </ConfigProvider>
    );
}

export default AdrListesi;
