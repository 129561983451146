import { Form, Button, DatePicker, Drawer, Input, Layout, List, notification, Popconfirm, Space, Spin, Select, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { ClockCircleFilled, DislikeFilled, LikeFilled, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useBildirimiGetir, useBildirimGuncelle, useBildirimSil } from "../hooks/useBildirim";
import { useAksiyonlariGetir } from "../hooks/useAksiyon";
import SahaDegerleri from "../components/SahaDegerleri";
import AksiyonGuncelle from "./AksiyonGuncelle";
import AksiyonEkle from "./AksiyonEkle";

const { Content } = Layout;
const { TextArea } = Input;

function BildirimGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [aksiyonEkleOpen, setAksiyonEkleOpen] = useState(false);
    const [aksiyonGuncelleOpen, setAksiyonGuncelleOpen] = useState(false);
    const [aksiyonID, setAksiyonID] = useState();

    const { aksiyonlar } = useAksiyonlariGetir();
    const { bildirim } = useBildirimiGetir(id);
    const { bildirimGuncelle } = useBildirimGuncelle(id);
    const { bildirimSil } = useBildirimSil(id);
    const [aksiyonListesi, setAksiyonListesi] = useState([]);

    const bildirimKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="bildirimKaydetButon" type="primary" htmlType="submit" loading={isPending}>
            {t("genel.kaydet")}
        </Button>
    );

    const bildirimSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="bildirimSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                bildirimSil(id);
                navigate("/bildirimList");
            }}
            style={{ width: 400 }}
        >
            <Button key="bildirimSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        setAksiyonListesi(aksiyonlar);
    }, [aksiyonlar]);

    useEffect(() => {
        if (bildirim) {
            form.setFieldsValue({
                aciklama: bildirim.aciklama,
                cozumOnerisi: bildirim.cozumOnerisi,
                bildirimZamani: bildirim.bildirimZamani ? dayjs(bildirim.bildirimZamani) : null,
                tur: bildirim.tur,
                onayDurumu: bildirim.onayDurumu,
                onayNotu: bildirim.onayNotu,
            });
            setLoading(false);
        }
    }, [bildirim]);

    const onSubmit = (e) => {
        setIsPending(true);
        bildirimGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="bildirimGuncelleKmyHeader"
                        caller="sayfaBaslik.bildirim"
                        buttons={[bildirimKaydetButon, bildirimSilButon]}
                        backURL="/bildirimList"
                    />
                    {contextHolder}
                    <Form.Item name="bildirimZamani" label={t("bildirim.bildirimZamani")}>
                        <DatePicker showTime disabled />
                    </Form.Item>
                    <Form.Item name="aciklama" label={t("bildirim.aciklama")}>
                        <TextArea placeholder={t("bildirim.aciklamaHint")} autoSize={true} readOnly />
                    </Form.Item>
                    <Form.Item name="cozumOnerisi" label={t("bildirim.cozumOnerisi")}>
                        <TextArea placeholder={t("bildirim.cozumOnerisi")} autoSize={true} readOnly />
                    </Form.Item>
                    <SahaDegerleri name="tur" id="bildirim.tur" label={t("bildirim.tur")} hint={t("bildirim.turHint")} />
                    <Form.Item name="onayDurumu" label={t("bildirim.onayDurumu")}>
                        <Select placeholder={t("bildirim.onayDurumu")}>
                            <Select.Option value="Y">{t("bildirim.Y")}</Select.Option>
                            <Select.Option value="E">{t("bildirim.E")}</Select.Option>
                            <Select.Option value="H">{t("bildirim.H")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="onayNotu" label={t("bildirim.onayNotu")}>
                        <Input placeholder={t("bildirim.onayNotuHint")} />
                    </Form.Item>
                </Form>
                <List
                    style={{ margin: 20 }}
                    header={
                        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                            <Button
                                type="primary"
                                disabled={bildirim.onayDurumu != "E"}
                                onClick={() => setAksiyonEkleOpen(true)}
                                icon={<PlusOutlined />}
                            >
                                Aksiyon Ekle
                            </Button>
                        </Space>
                    }
                    bordered
                    dataSource={aksiyonListesi}
                    renderItem={(item) => (
                        <List.Item key={item.aksiyonID}>
                            <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                <Space>
                                    <Button
                                        type="text"
                                        icon={<EditOutlined style={{ fontSize: 20 }} />}
                                        onClick={() => {
                                            setAksiyonID(item.aksiyonID);
                                            setAksiyonGuncelleOpen(true);
                                        }}
                                    />
                                    {item.aciklama}
                                </Space>
                                <Tooltip title={t(`aksiyon.${item.aksiyonDurumu}`)}>
                                    {item.aksiyonDurumu === "Y" ? (
                                        <ClockCircleFilled style={{ color: "red", fontSize: 20 }} />
                                    ) : item.aksiyonDurumu === "D" ? (
                                        <DislikeFilled style={{ color: "orange", fontSize: 20 }} />
                                    ) : (
                                        <LikeFilled style={{ color: "green", fontSize: 20 }} />
                                    )}
                                </Tooltip>
                            </Space>
                        </List.Item>
                    )}
                />
                <Drawer
                    title="Aksiyon Ekle"
                    width={600}
                    onClose={() => setAksiyonEkleOpen(false)}
                    open={aksiyonEkleOpen}
                    extra={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setAksiyonEkleOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="aksiyonEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
                                setAksiyonEkleOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <AksiyonEkle ilgiliTipi="bildirim" ilgiliID={id} acKapa={aksiyonEkleOpen} />
                </Drawer>
                <Drawer
                    title="Aksiyon"
                    width={600}
                    onClose={() => setAksiyonGuncelleOpen(false)}
                    open={aksiyonGuncelleOpen}
                    extra={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setAksiyonGuncelleOpen(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="aksiyonGuncelleForm"
                            htmlType="submit"
                            onClick={() => {
                                api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
                                setAksiyonGuncelleOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    {aksiyonGuncelleOpen ? <AksiyonGuncelle aksiyonID={aksiyonID} acKapa={aksiyonGuncelleOpen} /> : null}
                </Drawer>
            </Content>
        </Layout>
    );
}

export default BildirimGuncelle;
