import csgbSubject from "../constant/csgbSubject.json";

import { DatePicker, Form, Input } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useAksiyonEkle } from "../hooks/useAksiyon";
import SahaDegerleri from "../components/SahaDegerleri";
import BolumSelect from "../components/BolumSelect";
import KisiSelect from "../components/KisiSelect";
import { v4 } from "uuid";
import "dayjs/locale/tr.js";

const { TextArea } = Input;

function AksiyonEkle(props) {
    const { t } = useTranslation();
    const { aksiyonEkle } = useAksiyonEkle();

    const onSubmit = (e) => {
        e.aksiyonID = v4();
        e.ilgiliTipi = props.ilgiliTipi;
        e.ilgiliID = props.ilgiliID;
        console.log("form : ", e);
        aksiyonEkle({ e });
    };

    return (
        <Form
            id="aksiyonEkleForm"
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <SahaDegerleri name="aksiyonTipi" id="aksiyon.aksiyonTipi" label={t("aksiyon.aksiyonTipi")} />
            <BolumSelect name="bolumID" label={t("aksiyon.bolum")} hint={t("aksiyon.bolumHint")} />
            <SahaDegerleri name="konum" id="aksiyon.konum" label={t("aksiyon.konum")} hint={t("aksiyon.konumHint")} />
            <Form.Item name="hedefTarih" label={t("aksiyon.hedefTarih")}>
                <DatePicker />
            </Form.Item>
            <Form.Item name="aciklama" label={t("aksiyon.aciklama")}>
                <TextArea placeholder={t("aksiyon.aciklamaHint")} autoSize={true} />
            </Form.Item>
            <KisiSelect name="sorumlular" label={t("aksiyon.sorumlular")} mode="tags" />
        </Form>
    );
}

export default AksiyonEkle;
