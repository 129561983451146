import { Form, ConfigProvider, Select, Tooltip } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";

function GhsListesi(props) {
    const { t } = useTranslation();

    return (
        <ConfigProvider theme={{ components: { Select: { multipleItemHeight: 50 } } }}>
            <Form.Item name={props.name} label={props.label}>
                <Select placeholder={t("genel.seciniz")} mode="tags" style={{ height: 60, padding: 0, margin: 0 }}>
                    <Select.Option value="1">
                        <img src="https://pro.kmysistem.com/images/ghs01.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="2">
                        <img src="https://pro.kmysistem.com/images/ghs02.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="3">
                        <img src="https://pro.kmysistem.com/images/ghs03.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="4">
                        <img src="https://pro.kmysistem.com/images/ghs04.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="5">
                        <img src="https://pro.kmysistem.com/images/ghs05.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="6">
                        <img src="https://pro.kmysistem.com/images/ghs06.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="7">
                        <img src="https://pro.kmysistem.com/images/ghs07.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="8">
                        <img src="https://pro.kmysistem.com/images/ghs08.png" style={{ width: 50 }} />
                    </Select.Option>
                    <Select.Option value="9">
                        <img src="https://pro.kmysistem.com/images/ghs09.png" style={{ width: 50 }} />
                    </Select.Option>
                </Select>
            </Form.Item>
        </ConfigProvider>
    );
}

export default GhsListesi;
