import { Avatar, Button, Image, Input, Layout, Modal, notification, Space, Spin, Table, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import BildirimEkle from "./BildirimEkle";
import { useBildirimleriGetir } from "../hooks/useBildirim";
import { EditOutlined, SearchOutlined, LikeFilled, DislikeFilled, ClockCircleFilled } from "@ant-design/icons";
const { Header, Content } = Layout;

function BildirimList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { bildirimler } = useBildirimleriGetir();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(bildirimler);
        setFilteredSource(bildirimler);
        setIsPending(false);
    }, [bildirimler]);

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );
    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const aciklama = item.aciklama ? item.aciklama.toLowerCase() : "";
                    const comparisonString = `${aciklama}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            key: (saha, kayit) => kayit.bildirimID,
            render: (p) => (
                <Button
                    type="text"
                    key={p.bildirimID}
                    icon={<EditOutlined style={{ fontSize: 20 }} />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/bildirimGuncelle/${p.bildirimID}`);
                    }}
                />
            ),
        },
        {
            title: "",
            dataIndex: "onayDurumu",
            key: (saha, kayit) => kayit.bildirimID & "durum",
            render: (saha, kayit) => {
                return (
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Tooltip title={kayit.onayNotu}>
                            {" "}
                            {saha === "Y" ? (
                                <ClockCircleFilled style={{ color: "#aaa", fontSize: 20 }} />
                            ) : saha === "H" ? (
                                <DislikeFilled style={{ color: "red", fontSize: 20 }} />
                            ) : (
                                <LikeFilled style={{ color: "green", fontSize: 20 }} />
                            )}
                        </Tooltip>
                    </Space>
                );
            },
            filters: [
                { text: t("bildirim.Y"), value: "Y" },
                { text: t("bildirim.E"), value: "E" },
                { text: t("bildirim.H"), value: "H" },
            ],
            onFilter: (value, record) => record.onayDurumu === value,
            sorter: (a, b) => a.cozumOnerisi.localeCompare(b.cozumOnerisi),
        },
        {
            title: t("bildirim.tur"),
            dataIndex: "tur",
            key: (saha, kayit) => kayit.bildirimID & "tur",
            sorter: (a, b) => a.aciklama.localeCompare(b.aciklama),
        },
        {
            title: t("bildirim.aciklama"),
            dataIndex: "aciklama",
            key: (saha, kayit) => kayit.bildirimID & "aciklama",
            sorter: (a, b) => a.aciklama.localeCompare(b.aciklama),
        },
        {
            title: t("bildirim.cozumOnerisi"),
            dataIndex: "cozumOnerisi",
            render: function (html) {
                return <div dangerouslySetInnerHTML={{ __html: html }} />;
            },
            key: (saha, kayit) => kayit.bildirimID & "oneri",
            sorter: (a, b) => a.cozumOnerisi.localeCompare(b.cozumOnerisi),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.bildirimList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Input
                    key="searchBox"
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="bildirimList" dataSource={filteredSource} columns={columns} onChange={onChange} />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="bildirimEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <BildirimEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default BildirimList;
