import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Card, Col, Form, Button, Layout, notification, Row, Typography } from "antd";
import { useKisininFirmalariniGetir } from "../hooks/useKisi";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { useGetToken } from "../hooks/useGetToken";
import KmyHeader from "./KmyHeader";

const { Content } = Layout;

function FirmaSec() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { kisininFirmalari } = useKisininFirmalariniGetir();
    const { adSoyad, profilePhoto, kisiID, firmaID, firmaismi, firmaLogo, isAuth } = useGetUserInfo();

    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [seciliFirma, setSeciliFirma] = useState();
    const [seciliFirmaIsmi, setSeciliFirmaIsmi] = useState();
    const [seciliFirmaLogo, setSeciliFirmaLogo] = useState();
    const { accessToken } = useGetToken();

    const onSubmit = (e) => {
        setIsPending(true);
        const authInfo = {
            kisiID: kisiID,
            adSoyad: adSoyad,
            profilePhoto: profilePhoto,
            firmaID: seciliFirma,
            firmaismi: seciliFirmaIsmi,
            firmaLogo: seciliFirmaLogo,
        };
        localStorage.setItem("auth", JSON.stringify(authInfo));
        fetch(`http://89.19.24.18:3001/Kisi/byId/${kisiID}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({ firmaID: seciliFirma }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                navigate("/");
            });
        setIsPending(false);
    };

    const saveButton = (
        <Button key="saveButton" type="primary" htmlType="submit" loading={isPending} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    return (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 11 }}
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={(error) => {
                        console.error("onFinishFailed : " + { error });
                    }}
                >
                    <KmyHeader caller="sayfaBaslik.firmaSec" buttons={[saveButton]} backURL="/" />
                    {contextHolder}
                    <Row style={{ padding: 20 }}>
                        <Typography.Text style={{ width: "100%", marginBottom: 20, whiteSpace: "pre-line" }}>{t("kisi.firmaSec")}</Typography.Text>
                        {kisininFirmalari.map((p) => {
                            return (
                                <Card
                                    hoverable
                                    style={{
                                        width: 216,
                                        maxWidth: 216,
                                        margin: 3,
                                        borderWidth: 4,
                                        borderColor: firmaID == p.firmaID ? "#00F" : seciliFirma == p.firmaID ? "#080" : null,
                                    }}
                                    key={p.firmaID}
                                    onClick={() => {
                                        setSeciliFirma(p.firmaID);
                                        setSeciliFirmaIsmi(p.firmaismi);
                                        setSeciliFirmaLogo(p.logoURL);
                                    }}
                                    cover={
                                        <Col style={{ textAlign: "center" }}>
                                            <Avatar shape="square" src={`${p.logoURL}`} style={{ width: 210, height: 70, borderRadius: 8 }}></Avatar>
                                            <div style={{ marginBottom: -40 }}>{p.firmaismi}</div>
                                        </Col>
                                    }
                                ></Card>
                            );
                        })}
                    </Row>
                </Form>
            </Content>
        </Layout>
    );
}

export default FirmaSec;
