import tableAlist from "../constant/tableA.json";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Input, Row, Typography, Space } from "antd";
const { Text } = Typography;

function UnList(props) {
    const { t } = useTranslation();
    const [isPending, setIsPending] = useState(true);
    const [filteredSource, setFilteredSource] = useState([]);
    const [listOfTableA, setListOfTableA] = useState([]);

    useEffect(() => {
        setListOfTableA(tableAlist);
        setIsPending(false);
    }, []);

    const searchData = (searchText) => {
        if (searchText) {
            if (searchText.length > 2) {
                setFilteredSource(
                    listOfTableA.filter((item) => {
                        const unno = item.unNo ? item.unNo : "";
                        const desc = t(`dg.${item.unID}`) ? t(`dg.${item.unID}`).toLocaleLowerCase("tr") : "";
                        var comparisonString = `${unno} ${desc}`;
                        return comparisonString.includes(searchText.toLocaleLowerCase("tr"));
                    })
                );
            } else {
                setFilteredSource([]);
            }
        }
    };

    return (
        <>
            <Input
                placeholder={t("genel.enAz3")}
                prefix={<SearchOutlined />}
                loading={isPending}
                onChange={(e) => {
                    setIsPending(true);
                    searchData(e.target.value);
                    setIsPending(false);
                }}
            />
            <Row key="unListe">
                {filteredSource.map((p) => {
                    var etiket = "";
                    etiket = p.labels;
                    if (etiket) {
                        etiket = etiket.split(" ")[0];
                        etiket = etiket.replaceAll(".", "");
                        if (etiket.length == 1) {
                            etiket = `${etiket}0`;
                        }
                    }
                    var pg;
                    if (p.packingGroup != "") {
                        pg = (
                            <>
                                <b>{t("dg.packingGroup")}: </b> {p.packingGroup}
                            </>
                        );
                    }
                    return (
                        <Card
                            hoverable
                            style={{
                                marginTop: 5,
                                marginBottom: 5,
                                width: "100%",
                                borderColor: props.unID == p.unID ? "#338" : "#DDD",
                                backgroundColor: props.unID == p.unID ? "#FFD" : "#FFF",
                            }}
                            bordered={true}
                            onClick={() => {
                                props.setUnID(p.unID);
                                props.setErgNo(p.ergNo);
                            }}
                            key={p.unID}
                        >
                            <Space.Compact>
                                <Avatar
                                    src={`https://pro.kmysistem.com/images/adr${etiket}.png`}
                                    style={{ marginLeft: -20, width: 60, height: 60, minWidth: 60 }}
                                />
                                <Col>
                                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>{`UN ${p.unNo}`}</Text>

                                    <Row>
                                        <Text>{t(`dg.${p.unID}`)}</Text>
                                    </Row>
                                    <Row>
                                        <b>&nbsp;{t("dg.class")}:</b> {p.class}
                                        <b>&nbsp;{t("dg.classificationCode")}:</b> {p.classificationCode}&nbsp;
                                        {pg}
                                    </Row>
                                </Col>
                            </Space.Compact>
                        </Card>
                    );
                })}
            </Row>
        </>
    );
}

export default UnList;
