import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConfigProvider, Layout, theme } from "antd";
import TableA from "./pages/TableA";
import ChangePassword from "./pages/ChangePassword";
import KisiGuncelle from "./pages/KisiGuncelle";
import KisiEkle from "./pages/KisiEkle";
import Login from "./pages/Login";
import Home from "./pages/Home";
import FirmaEkle from "./pages/FirmaEkle";
import FirmaList from "./pages/FirmaList";
import FirmaGuncelle from "./pages/FirmaGuncelle";
import RolGuncelle from "./pages/RolGuncelle";
import KisiList from "./pages/KisiList";
import KisiFirma from "./pages/KisiFirma";

import tr_TR from "antd/locale/tr_TR";
import { auth } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import KursList from "./pages/KursList";
import RolList from "./pages/RolList";
import KursGuncelle from "./pages/KursGuncelle";
import KursBolumGuncelle from "./pages/KursBolumGuncelle";
import KursSoruGuncelle from "./pages/KursSoruGuncelle";
import { useTranslation } from "react-i18next";
import EgitimList from "./pages/EgitimList";
import EgitimGuncelle from "./pages/EgitimGuncelle";
import BelgeGuncelle from "./pages/BelgeGuncelle";
import RiskGuncelle from "./pages/RiskGuncelle";
import RiskList from "./pages/RiskList";
import BildirimList from "./pages/BildirimList";
import YanginTupuList from "./pages/YanginTupuList";
import YanginTupuGuncelle from "./pages/YanginTupuGuncelle";
import YanginDolabiList from "./pages/YanginDolabiList";
import YanginDolabiGuncelle from "./pages/YanginDolabiGuncelle";
import MerdivenList from "./pages/MerdivenList";
import MerdivenGuncelle from "./pages/MerdivenGuncelle";
import UetdsTc from "./pages/UetdsTc";
import UetdsPlaka from "./pages/UetdsPlaka";
import TespitList from "./pages/TespitList";
import TespitGuncelle from "./pages/TespitGuncelle";
import TatbikatList from "./pages/TatbikatList";
import TatbikatGuncelle from "./pages/TatbikatGuncelle";
import EkipmanList from "./pages/EkipmanList";
import EkipmanGuncelle from "./pages/EkipmanGuncelle";
import EnvanterList from "./pages/EnvanterList";
import EnvanterGuncelle from "./pages/EnvanterGuncelle";
import BildirimGuncelle from "./pages/BildirimGuncelle";
import KisiKimlik from "./pages/KisiKimlik";
import EnvanterEkle from "./pages/EnvanterEkle";
import FirmaSec from "./pages/FirmaSec";
import AcilDurumPlani from "./pages/AcilDurumPlani";
import AdpList from "./pages/AdpList";

function App() {
    const [user, setUser] = useState();
    const { i18n } = useTranslation();

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser === null) {
                localStorage.removeItem("auth");
                localStorage.removeItem("kmyToken");
            } else {
                currentUser
                    .getIdToken(true)
                    .then(function (idToken) {
                        const tokenInfo = {
                            accessToken: idToken,
                        };
                        localStorage.setItem("kmyToken", JSON.stringify(tokenInfo));
                    })
                    .catch(function (error) {
                        console.log("error :", error);
                        // Handle error
                    });
            }
        });
    }, []);

    const { Content } = Layout;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    document.documentElement.lang = i18n.language;
    i18n.on("languageChanged", (lng) => (document.documentElement.lang = lng));

    return (
        <ConfigProvider locale={tr_TR}>
            <Content className="notranslate">
                <Router>
                    <Routes>
                        (user === null) ?
                        <Route path="/login" exact element={<Login />} />
                        :
                        <Route path="/" currUser={user} exact element={<Home />} />
                        <Route path="/KisiFirma/:id" exact element={<KisiFirma />} />
                        <Route path="/tableA" exact element={<TableA />} />
                        <Route path="/changepassword" exact Component={ChangePassword} />
                        <Route path="/KisiEkle" exact element={<KisiEkle />} />
                        <Route path="/KisiList" exact element={<KisiList />} />
                        <Route path="/KisiGuncelle/:id" exact element={<KisiGuncelle />} />
                        <Route path="/kisiKimlik/:id" exact element={<KisiKimlik />} />
                        <Route path="/firmaList" exact element={<FirmaList />} />
                        <Route path="/FirmaEkle" exact element={<FirmaEkle />} />
                        <Route path="/FirmaGuncelle/:id" exact element={<FirmaGuncelle />} />
                        <Route path="/kursList" exact element={<KursList />} />
                        <Route path="/kursGuncelle/:id" exact element={<KursGuncelle />} />
                        <Route path="/kursBolumGuncelle/:id" exact element={<KursBolumGuncelle />} />
                        <Route path="/kursSoruGuncelle/:id" exact element={<KursSoruGuncelle />} />
                        <Route path="/egitimList" exact element={<EgitimList />} />
                        <Route path="/egitimGuncelle/:id" exact element={<EgitimGuncelle />} />
                        <Route path="/rolList" exact element={<RolList />} />
                        <Route path="/rolGuncelle/:id" exact element={<RolGuncelle />} />
                        <Route path="/belgeGuncelle/:id" exact element={<BelgeGuncelle />} />
                        <Route path="/riskList" exact element={<RiskList />} />
                        <Route path="/riskGuncelle/:id" exact element={<RiskGuncelle />} />
                        <Route path="/bildirimList" exact element={<BildirimList />} />
                        <Route path="/bildirimGuncelle/:id" exact element={<BildirimGuncelle />} />
                        <Route path="/yanginTupuList" exact element={<YanginTupuList />} />
                        <Route path="/yanginTupuGuncelle/:id" exact element={<YanginTupuGuncelle />} />
                        <Route path="/yanginDolabiList" exact element={<YanginDolabiList />} />
                        <Route path="/yanginDolabiGuncelle/:id" exact element={<YanginDolabiGuncelle />} />
                        <Route path="/merdivenList" exact element={<MerdivenList />} />
                        <Route path="/merdivenGuncelle/:id" exact element={<MerdivenGuncelle />} />
                        <Route path="/uetdsTc" exact element={<UetdsTc />} />
                        <Route path="/uetdsPlaka" exact element={<UetdsPlaka />} />
                        <Route path="/tespitList" exact element={<TespitList />} />
                        <Route path="/tespitGuncelle/:id" exact element={<TespitGuncelle />} />
                        <Route path="/tatbikatList" exact element={<TatbikatList />} />
                        <Route path="/tatbikatGuncelle/:id" exact element={<TatbikatGuncelle />} />
                        <Route path="/ekipmanList" exact element={<EkipmanList />} />
                        <Route path="/ekipmanGuncelle/:id" exact element={<EkipmanGuncelle />} />
                        <Route path="/envanterEkle" exact element={<EnvanterEkle />} />
                        <Route path="/envanterList" exact element={<EnvanterList />} />
                        <Route path="/envanterGuncelle/:id" exact element={<EnvanterGuncelle />} />
                        <Route path="/firmaSec" exact element={<FirmaSec />} />
                        <Route path="/adpList" exact element={<AdpList />} />
                        <Route path="/acilDurumPlani" exact element={<AcilDurumPlani />} />
                        <Route path="/acilDurumPlani/:id" exact element={<AcilDurumPlani />} />
                    </Routes>
                </Router>
            </Content>
        </ConfigProvider>
    );
}

export default App;
