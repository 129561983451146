import { Form, Button, Input, Select, Space } from "antd";
import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

function KisiSelect(props) {
    const { t } = useTranslation();
    const [kisiler, setKisiler] = useState([]);
    const [kisiOptions, setKisiOptions] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
    const { firmaID } = useGetUserInfo();

    const yetkiler = [99999]; //auth.permissions;
    const [kisiIsmi, setKisiIsmi] = useState("");

    const kisileriGetir = async () => {
        let unsubscribe;
        fetch("http://89.19.24.18:3001/Kisi", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setKisiler(data);
            });
        setKisiler(unsubscribe);
    };

    useEffect(() => {
        if (kisiler) {
            const opts = kisiler.map((item) => ({ value: String(item.kisiID), label: `${item.isim} ${item.soyad}` }));
            setKisiOptions(opts);
        }
    }, [kisiler]);

    useEffect(() => {
        kisileriGetir();
    }, []);

    const kisiRef = useRef(null);

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select placeholder={props.hint} mode={props.mode} dropdownRender={(menu) => <>{menu}</>}>
                {kisiOptions.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default KisiSelect;
