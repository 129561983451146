import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button, Checkbox, Col, Input, Layout, notification, Popconfirm, Row, Space, Spin, Tooltip } from "antd";
import { DeleteFilled, EyeFilled, EditFilled, FileFilled, PlusSquareFilled, ThunderboltFilled } from "@ant-design/icons";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useRolGetir, useRolGuncelle, useRolSil } from "../hooks/useRol";
import KmyHeader from "./KmyHeader";

const { Header, Content } = Layout;

function RolGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const [yetkiler, setYetkiler] = useState(["ADMIN"]);
    const [rolYetkileri, setRolYetkileri] = useState([]);
    const [errMessage, setErrMessage] = useState(null);

    const [form] = Form.useForm();
    const { rol } = useRolGetir(id);
    const { rolGuncelle } = useRolGuncelle(id);
    const { rolSil } = useRolSil(id);

    useEffect(() => {
        setRolYetkileri(rol.yetkiler);
        form.setFieldsValue({
            isim: rol.isim,
        });
    }, [rol]);

    useEffect(() => {
        if (rolYetkileri) {
            form.setFieldsValue({
                isim: rol.isim,
                ADMIN: rolYetkileri.includes("ADMIN"),
                KISI: rolYetkileri.includes("KISI"),
                KISIKG: rolYetkileri.includes("KISIKG"),
                KISIKD: rolYetkileri.includes("KISIKD"),
                KISIKE: rolYetkileri.includes("KISIKE"),
                KISIKS: rolYetkileri.includes("KISIKS"),
                KISIBG: rolYetkileri.includes("KISIBG"),
                KISIBY: rolYetkileri.includes("KISIBY"),
                KISIBE: rolYetkileri.includes("KISIBE"),
                KISIBS: rolYetkileri.includes("KISIBS"),
                KISIBO: rolYetkileri.includes("KISIBO"),
                FIRM: rolYetkileri.includes("FIRM"),
                FIRMKG: rolYetkileri.includes("FIRMKG"),
                FIRMKD: rolYetkileri.includes("FIRMKD"),
                FIRMKE: rolYetkileri.includes("FIRMKE"),
                FIRMKS: rolYetkileri.includes("FIRMKS"),
                FIRMBG: rolYetkileri.includes("FIRMBG"),
                FIRMBY: rolYetkileri.includes("FIRMBY"),
                FIRMBE: rolYetkileri.includes("FIRMBE"),
                FIRMBS: rolYetkileri.includes("FIRMBS"),
                FIRMBO: rolYetkileri.includes("FIRMBO"),
                YATU: rolYetkileri.includes("YATU"),
                YATUKG: rolYetkileri.includes("YATUKG"),
                YATUKD: rolYetkileri.includes("YATUKD"),
                YATUKE: rolYetkileri.includes("YATUKE"),
                YATUKS: rolYetkileri.includes("YATUKS"),
                YATUBG: rolYetkileri.includes("YATUBG"),
                YATUBY: rolYetkileri.includes("YATUBY"),
                YATUBE: rolYetkileri.includes("YATUBE"),
                YATUBS: rolYetkileri.includes("YATUBS"),
                YATUBO: rolYetkileri.includes("YATUBO"),
                YADO: rolYetkileri.includes("YADO"),
                YADOKG: rolYetkileri.includes("YADOKG"),
                YADOKD: rolYetkileri.includes("YADOKD"),
                YADOKE: rolYetkileri.includes("YADOKE"),
                YADOKS: rolYetkileri.includes("YADOKS"),
                YADOBG: rolYetkileri.includes("YADOBG"),
                YADOBY: rolYetkileri.includes("YADOBY"),
                YADOBE: rolYetkileri.includes("YADOBE"),
                YADOBS: rolYetkileri.includes("YADOBS"),
                YADOBO: rolYetkileri.includes("YADOBO"),
                MERD: rolYetkileri.includes("MERD"),
                MERDKG: rolYetkileri.includes("MERDKG"),
                MERDKD: rolYetkileri.includes("MERDKD"),
                MERDKE: rolYetkileri.includes("MERDKE"),
                MERDKS: rolYetkileri.includes("MERDKS"),
                MERDBG: rolYetkileri.includes("MERDBG"),
                MERDBY: rolYetkileri.includes("MERDBY"),
                MERDBE: rolYetkileri.includes("MERDBE"),
                MERDBS: rolYetkileri.includes("MERDBS"),
                MERDBO: rolYetkileri.includes("MERDBO"),
                RISK: rolYetkileri.includes("RISK"),
                RISKKG: rolYetkileri.includes("RISKKG"),
                RISKKD: rolYetkileri.includes("RISKKD"),
                RISKKE: rolYetkileri.includes("RISKKE"),
                RISKKS: rolYetkileri.includes("RISKKS"),
                RISKBG: rolYetkileri.includes("RISKBG"),
                RISKBY: rolYetkileri.includes("RISKBY"),
                RISKBE: rolYetkileri.includes("RISKBE"),
                RISKBS: rolYetkileri.includes("RISKBS"),
                RISKBO: rolYetkileri.includes("RISKBO"),
                ADUP: rolYetkileri.includes("ADUP"),
                ADUPKG: rolYetkileri.includes("ADUPKG"),
                ADUPKD: rolYetkileri.includes("ADUPKD"),
                ADUPKE: rolYetkileri.includes("ADUPKE"),
                ADUPKS: rolYetkileri.includes("ADUPKS"),
                ADUPBG: rolYetkileri.includes("ADUPBG"),
                ADUPBY: rolYetkileri.includes("ADUPBY"),
                ADUPBE: rolYetkileri.includes("ADUPBE"),
                ADUPBS: rolYetkileri.includes("ADUPBS"),
                ADUPBO: rolYetkileri.includes("ADUPBO"),
            });
            setLoading(false);
        }
    }, [rolYetkileri]);

    const onSubmit = (e) => {
        setLoading(true);
        console.log(e);
        var yetkiDizisi = [];

        if (form.getFieldValue(["ADMIN"])) {
            yetkiDizisi.push("ADMIN");
        } else {
            if (form.getFieldValue(["KISI"])) {
                yetkiDizisi.push("KISI");
            } else {
                if (form.getFieldValue(["KISIKG"])) yetkiDizisi.push("KISIKG");
                if (form.getFieldValue(["KISIKD"])) yetkiDizisi.push("KISIKD");
                if (form.getFieldValue(["KISIKE"])) yetkiDizisi.push("KISIKE");
                if (form.getFieldValue(["KISIKS"])) yetkiDizisi.push("KISIKS");
                if (form.getFieldValue(["KISIBG"])) yetkiDizisi.push("KISIBG");
                if (form.getFieldValue(["KISIBY"])) yetkiDizisi.push("KISIBY");
                if (form.getFieldValue(["KISIBE"])) yetkiDizisi.push("KISIBE");
                if (form.getFieldValue(["KISIBS"])) yetkiDizisi.push("KISIBS");
            }
            if (form.getFieldValue(["FIRM"])) {
                yetkiDizisi.push("FIRM");
            } else {
                if (form.getFieldValue(["FIRMKG"])) yetkiDizisi.push("FIRMKG");
                if (form.getFieldValue(["FIRMKD"])) yetkiDizisi.push("FIRMKD");
                if (form.getFieldValue(["FIRMKE"])) yetkiDizisi.push("FIRMKE");
                if (form.getFieldValue(["FIRMKS"])) yetkiDizisi.push("FIRMKS");
                if (form.getFieldValue(["FIRMBG"])) yetkiDizisi.push("FIRMBG");
                if (form.getFieldValue(["FIRMBY"])) yetkiDizisi.push("FIRMBY");
                if (form.getFieldValue(["FIRMBE"])) yetkiDizisi.push("FIRMBE");
                if (form.getFieldValue(["FIRMBS"])) yetkiDizisi.push("FIRMBS");
            }
            if (form.getFieldValue(["YATU"])) {
                yetkiDizisi.push("YATU");
            } else {
                if (form.getFieldValue(["YATUKG"])) yetkiDizisi.push("YATUKG");
                if (form.getFieldValue(["YATUKD"])) yetkiDizisi.push("YATUKD");
                if (form.getFieldValue(["YATUKE"])) yetkiDizisi.push("YATUKE");
                if (form.getFieldValue(["YATUKS"])) yetkiDizisi.push("YATUKS");
                if (form.getFieldValue(["YATUBG"])) yetkiDizisi.push("YATUBG");
                if (form.getFieldValue(["YATUBY"])) yetkiDizisi.push("YATUBY");
                if (form.getFieldValue(["YATUBE"])) yetkiDizisi.push("YATUBE");
                if (form.getFieldValue(["YATUBS"])) yetkiDizisi.push("YATUBS");
            }
            if (form.getFieldValue(["YADO"])) {
                yetkiDizisi.push("YADO");
            } else {
                if (form.getFieldValue(["YADOKG"])) yetkiDizisi.push("YADOKG");
                if (form.getFieldValue(["YADOKD"])) yetkiDizisi.push("YADOKD");
                if (form.getFieldValue(["YADOKE"])) yetkiDizisi.push("YADOKE");
                if (form.getFieldValue(["YADOKS"])) yetkiDizisi.push("YADOKS");
                if (form.getFieldValue(["YADOBG"])) yetkiDizisi.push("YADOBG");
                if (form.getFieldValue(["YADOBY"])) yetkiDizisi.push("YADOBY");
                if (form.getFieldValue(["YADOBE"])) yetkiDizisi.push("YADOBE");
                if (form.getFieldValue(["YADOBS"])) yetkiDizisi.push("YADOBS");
            }
            if (form.getFieldValue(["MERD"])) {
                yetkiDizisi.push("MERD");
            } else {
                if (form.getFieldValue(["MERDKG"])) yetkiDizisi.push("MERDKG");
                if (form.getFieldValue(["MERDKD"])) yetkiDizisi.push("MERDKD");
                if (form.getFieldValue(["MERDKE"])) yetkiDizisi.push("MERDKE");
                if (form.getFieldValue(["MERDKS"])) yetkiDizisi.push("MERDKS");
                if (form.getFieldValue(["MERDBG"])) yetkiDizisi.push("MERDBG");
                if (form.getFieldValue(["MERDBY"])) yetkiDizisi.push("MERDBY");
                if (form.getFieldValue(["MERDBE"])) yetkiDizisi.push("MERDBE");
                if (form.getFieldValue(["MERDBS"])) yetkiDizisi.push("MERDBS");
            }
            if (form.getFieldValue(["RISK"])) {
                yetkiDizisi.push("RISK");
            } else {
                if (form.getFieldValue(["RISKKG"])) yetkiDizisi.push("RISKKG");
                if (form.getFieldValue(["RISKKD"])) yetkiDizisi.push("RISKKD");
                if (form.getFieldValue(["RISKKE"])) yetkiDizisi.push("RISKKE");
                if (form.getFieldValue(["RISKKS"])) yetkiDizisi.push("RISKKS");
                if (form.getFieldValue(["RISKBG"])) yetkiDizisi.push("RISKBG");
                if (form.getFieldValue(["RISKBY"])) yetkiDizisi.push("RISKBY");
                if (form.getFieldValue(["RISKBE"])) yetkiDizisi.push("RISKBE");
                if (form.getFieldValue(["RISKBS"])) yetkiDizisi.push("RISKBS");
            }
            if (form.getFieldValue(["ADUP"])) {
                yetkiDizisi.push("ADUP");
            } else {
                if (form.getFieldValue(["ADUPKG"])) yetkiDizisi.push("ADUPKG");
                if (form.getFieldValue(["ADUPKD"])) yetkiDizisi.push("ADUPKD");
                if (form.getFieldValue(["ADUPKE"])) yetkiDizisi.push("ADUPKE");
                if (form.getFieldValue(["ADUPKS"])) yetkiDizisi.push("ADUPKS");
                if (form.getFieldValue(["ADUPBG"])) yetkiDizisi.push("ADUPBG");
                if (form.getFieldValue(["ADUPBY"])) yetkiDizisi.push("ADUPBY");
                if (form.getFieldValue(["ADUPBE"])) yetkiDizisi.push("ADUPBE");
                if (form.getFieldValue(["ADUPBS"])) yetkiDizisi.push("ADUPBS");
            }
        }
        console.log("dizi : ", yetkiDizisi);
        e.yetkiler = yetkiDizisi;
        rolGuncelle({ id, e });

        setLoading(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    const rolKaydetButon = (yetkiler.includes("ADMIN") || yetkiler.includes("ROLE")) && (
        <Button key="rolKaydetButon" type="primary" htmlType="submit" loading={loading} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const rolSilButon = (yetkiler.includes("ADMIN") || yetkiler.includes("ROLE")) && (
        <Popconfirm
            key="rolSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                rolSil(id);
                navigate("/rolList");
            }}
            style={{ width: 400 }}
        >
            <Button key="rolSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    const _admin = Form.useWatch("ADMIN", form);
    const _kisixx = Form.useWatch("KISI", form);
    const _firmxx = Form.useWatch("FIRM", form);
    const _yatuxx = Form.useWatch("YATU", form);
    const _yadoxx = Form.useWatch("YADO", form);
    const _merdxx = Form.useWatch("MERD", form);
    const _riskxx = Form.useWatch("RISK", form);
    const _adupxx = Form.useWatch("ADUP", form);

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="rolGuncelleKmyHeader"
                        caller="sayfaBaslik.rolGuncelle"
                        buttons={[rolKaydetButon, rolSilButon]}
                        backURL="/rolList"
                    />
                    {contextHolder}
                    <Form.Item name="isim" label={t("yetkiler.isim")} style={{ marginTop: 20 }}>
                        <Input placeholder={t("yetkiler.isimHint")} maxLength={255} />
                    </Form.Item>
                    <Form.Item name="ADMIN" valuePropName="checked" label={t("yetkiler.TUMU")}>
                        <Checkbox>{t("yetkiler.ADMIN")}</Checkbox>
                    </Form.Item>
                    <Row>
                        <Col span={9}></Col>
                        <Col span={1} style={{ backgroundColor: "#FCC" }}></Col>
                        <Col span={4} align="middle" style={{ backgroundColor: "#CEC", marginBottom: 1 }}>
                            <Tooltip title={t("yetkiler.kayit")}>
                                <FileFilled />
                            </Tooltip>
                        </Col>
                        <Col span={4} align="middle" style={{ backgroundColor: "#CCE", marginBottom: 1 }}>
                            <Tooltip title={t("yetkiler.belge")}>
                                <AttachmentIcon />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={9} align="middle"></Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#FCC" }}>
                            <Tooltip title={t("yetkiler.tamYetkili")}>
                                <ThunderboltFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CEC" }}>
                            <Tooltip title={t("yetkiler.gorur")}>
                                <EyeFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CEC" }}>
                            <Tooltip title={t("yetkiler.degistirir")}>
                                <EditFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CEC" }}>
                            <Tooltip title={t("yetkiler.ekler")}>
                                <PlusSquareFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CEC" }}>
                            <Tooltip title={t("yetkiler.siler")}>
                                <DeleteFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CCE" }}>
                            <Tooltip title={t("yetkiler.gorur")}>
                                <EyeFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CCE" }}>
                            <Tooltip title={t("yetkiler.degistirir")}>
                                <EditFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CCE" }}>
                            <Tooltip title={t("yetkiler.ekler")}>
                                <PlusSquareFilled />
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle" style={{ backgroundColor: "#CCE" }}>
                            <Tooltip title={t("yetkiler.siler")}>
                                <DeleteFilled />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5, marginBottom: -18, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.KISI")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISI" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.KISIBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="KISIBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _kisixx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.FIRM")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRM" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.FIRMBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="FIRMBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _firmxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.YATU")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATU" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YATUBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YATUBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yatuxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.YADO")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADO" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.YADOBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="YADOBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _yadoxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.MERD")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERD" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.MERDBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="MERDBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _merdxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.RISK")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISK" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.RISKBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="RISKBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _riskxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
                        <Col span={9} align="right" style={{ paddingRight: 10 }}>
                            {t("yetkiler.ADUP")}
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPXX")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUP" valuePropName="checked">
                                        <Checkbox disabled={_admin} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPKG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPKG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPKD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPKD" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPKE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPKE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPKS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPKS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPBG")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPBG" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPBD")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPBY" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPBE")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPBE" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                        <Col span={1} align="middle">
                            <Tooltip title={t("yetkiler.ADUPBS")}>
                                <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                    <Form.Item name="ADUPBS" valuePropName="checked">
                                        <Checkbox disabled={_admin || _adupxx} />
                                    </Form.Item>
                                </Space>
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </Layout>
    );
}

export default RolGuncelle;
