import { Button, Input, Layout, notification, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import KmyHeader from "./KmyHeader";
import { useEnvanterleriGetir } from "../hooks/useEnvanter";
const { Header, Content } = Layout;

function EnvanterList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { envanterler } = useEnvanterleriGetir();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(envanterler);
        setFilteredSource(envanterler);
        setIsPending(false);
    }, [envanterler]);

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                navigate(`/envanterEkle`);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );
    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const isim = item.isim ? item.isim.toLowerCase() : "";
                    const comparisonString = `${isim}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            key: (p) => p.envanterID,
            render: (p) => (
                <Button
                    type="text"
                    key={p.envanterID}
                    icon={<EditOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/envanterGuncelle/${p.envanterID}`);
                    }}
                />
            ),
        },
        {
            title: t("envanter.isim"),
            dataIndex: "isim",
            key: (p) => p.envanterID & "isim",
            sorter: (a, b) => a.isim.localeCompare(b.isim),
        },
        {
            title: t("envanter.kod"),
            dataIndex: "kod",
            key: (p) => p.envanterID & "kod",
            sorter: (a, b) => a.kod.localeCompare(b.kod),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.envanterList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Input
                    key="searchBox"
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="envanterList" dataSource={filteredSource} columns={columns} onChange={onChange} />
            </Content>
        </Layout>
    );
}

export default EnvanterList;
