import { Form, Button, Divider, Input, Layout, notification, Popconfirm, Select, Space, Spin, Tabs, Tooltip, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import { useEnvanteriGetir, useEnvanterGuncelle, useEnvanterSil } from "../hooks/useEnvanter";
import "react-quill/dist/quill.snow.css";
import pKodlari from "../constant/pKodlari.json";
import hKodlari from "../constant/hKodlari.json";
import tableAlist from "../constant/tableA.json";
import GhsListesi from "../components/GhsListesi";
import AdrListesi from "../components/AdrListesi";

const { Text, Paragraph } = Typography;
const { Content } = Layout;

function EnvanterGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [filteredSource, setFilteredSource] = useState([]);
    const [listOfTableA, setListOfTableA] = useState([]);
    const [envErgNo, setEnvErgNo] = useState();
    const [envUnID, setEnvUnID] = useState();
    const [envTtn, setEnvTtn] = useState();
    const [hBilgi, setHbilgi] = useState();
    const [hSecili, setHsecili] = useState([]);
    const [pBilgi, setPbilgi] = useState();
    const [pSecili, setPsecili] = useState([]);

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { envanter } = useEnvanteriGetir(id);
    const { envanterGuncelle } = useEnvanterGuncelle(id);
    const { envanterSil } = useEnvanterSil(id);

    const envanterKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="envanterKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const envanterSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="envanterSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                envanterSil(id);
                navigate("/envanterList");
            }}
            style={{ width: 400 }}
        >
            <Button key="envanterSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (envanter) {
            form.setFieldsValue({
                isim: envanter.isim,
                kod: envanter.kod,
                unID: envanter.unID,
                maddeHali: envanter.maddeHali,
                ambalaj: envanter.ambalaj,
                hKodlari: envanter.hKodlari,
                pKodlari: envanter.pKodlari,
                donma: envanter.donma,
                erime: envanter.erime,
                parlama: envanter.parlama,
                nfpaSaglik: envanter.nfpaSaglik,
                nfpaYanicilik: envanter.nfpaYanicilik,
                nfpaReaktivite: envanter.nfpaReaktivite,
                nfpaOzelnot: envanter.nfpaOzelnot,
                hmisSaglik: envanter.hmisSaglik,
                hmisYanicilik: envanter.hmisYanicilik,
                hmisReaktivite: envanter.hmisReaktivite,
                hmisKkd: envanter.hmisKkd,
                ghsEtiket: envanter.ghsEtiket,
                adrEtiket: envanter.adrEtiket,
                seveso: envanter.seveso,
                casNo: envanter.casNo,
                ergNo: envanter.ergNo,
                eacNo: envanter.eacNo,
                ptSaglik: envanter.ptSaglik,
                ptYangin: envanter.ptYangin,
                kamuGuvenligi: envanter.kamuGuvenligi,
                koruyucuEkipman: envanter.koruyucuEkipman,
                tahliyeBasta: envanter.tahliyeBasla,
                tahliyeSizinti: envanter.tahliyeSizinti,
                tahliyeYangin: envanter.tahliyeYangin,
                mudahaleYangin: envanter.mudahaleYangin,
                mudahaleSizinti: envanter.mudahaleSizinti,
                ilkYardim: envanter.ilkYardim,
                yanginGunduz: envanter.yanginGunduz,
                yanginGece: envanter.yanginGece,
                mudahaleYangin: envanter.mudahaleYangin,
                sizintiGunduz: envanter.sizintiGunduz,
                sizintiGece: envanter.sizintiGece,
            });
            setHsecili(envanter.hKodlari);
            setPsecili(envanter.pKodlari);
            hKodDetayi();
            pKodDetayi();
            setEnvErgNo(envanter.ergNo);
            setEnvUnID(envanter.unID);
            var resultUN = tableAlist.filter(function (item) {
                return item.unID == envanter.unID;
            });
            if (resultUN.length > 0) setEnvTtn(resultUN[0]["hazardIdNo"]);
            setLoading(false);
        }
    }, [envanter]);

    useEffect(() => {
        hKodDetayi();
    }, [hSecili]);

    useEffect(() => {
        pKodDetayi();
    }, [pSecili]);

    const hKodDetayi = () => {
        if (hSecili) {
            const detay = hSecili.map((item) => {
                return `${item}-${t(`hpKodlari.${item}`)}\n`;
            });
            setHbilgi(detay.join(""));
        }
    };

    const pKodDetayi = () => {
        if (pSecili) {
            const detay = pSecili.map((item) => {
                return `${item}-${t(`hpKodlari.${item}`)}\n`;
            });
            setPbilgi(detay.join(""));
        }
    };

    useEffect(() => {
        if (tableAlist) {
            setListOfTableA(tableAlist);
            setFilteredSource(tableAlist);
        }
    }, [tableAlist]);

    const searchData = (searchText) => {
        setFilteredSource(listOfTableA);
        if (searchText) {
            setFilteredSource(
                listOfTableA.filter((item) => {
                    const unno = item.unNo ? item.unNo : "";
                    const desc = t(`dg.${item.unID}`) ? t(`dg.${item.unID}`).toLowerCase() : "";
                    const comparisonString = `${unno} ${desc}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const onSubmit = (e) => {
        setIsPending(true);
        console.log("form bilgisi : ", e);
        envanterGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="envanterGuncelleKmyHeader"
                        caller="sayfaBaslik.envanter"
                        buttons={[envanterKaydetButon, envanterSilButon]}
                        backURL="/envanterList"
                    />
                    {contextHolder}
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "1",
                                label: t("envanter.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item name="isim" label={t("envanter.isim")}>
                                            <Input placeholder={t("envanter.isimHint")} />
                                        </Form.Item>
                                        <Form.Item name="kod" label={t("envanter.kod")}>
                                            <Input placeholder={t("envanter.kodHint")} />
                                        </Form.Item>
                                        <Form.Item name="maddeHali" label={t("envanter.maddeHali")}>
                                            <Select placeholder={t("envanter.maddeHali")}>
                                                <Select.Option value="K">{t("envanter.kati")}</Select.Option>
                                                <Select.Option value="S">{t("envanter.sivi")}</Select.Option>
                                                <Select.Option value="G">{t("envanter.gaz")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="ambalaj" label={t("envanter.ambalaj")}>
                                            <Input placeholder={t("envanter.ambalajHint")} />
                                        </Form.Item>

                                        <Form.Item label={t("envanter.sicaklik")}>
                                            <Space.Compact style={{ width: "100%" }}>
                                                <Tooltip title={t("envanter.donmaHint")}>
                                                    <Form.Item name="donma" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                                                        <Input placeholder={t("envanter.donmaHint")} addonAfter={t("genel.c")} />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Tooltip title={t("envanter.erimeHint")}>
                                                    <Form.Item name="erime" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                                                        <Input placeholder={t("envanter.erimeHint")} addonAfter={t("genel.c")} />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Tooltip title={t("envanter.parlamaHint")}>
                                                    <Form.Item name="parlama" style={{ width: "100%", marginBottom: 0 }}>
                                                        <Input placeholder={t("envanter.parlamaHint")} addonAfter={t("genel.c")} />
                                                    </Form.Item>
                                                </Tooltip>
                                            </Space.Compact>
                                        </Form.Item>
                                        <Form.Item name="hKodlari" label={t("envanter.hKodlari")} style={{ width: "100%" }}>
                                            <Select
                                                options={hKodlari}
                                                mode="tags"
                                                placeholder={t("genel.seciniz")}
                                                optionRender={(item) => {
                                                    return (
                                                        <>
                                                            {item["value"]} {t(`hpKodlari.${item["value"]}`)}
                                                        </>
                                                    );
                                                }}
                                                onChange={() => {
                                                    setHsecili(form.getFieldValue(["hKodlari"]));
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("envanter.kodAciklama")} style={{ marginTop: -20 }}>
                                            <Typography.Text style={{ whiteSpace: "pre-line" }}>{hBilgi}</Typography.Text>
                                        </Form.Item>
                                        <Form.Item name="pKodlari" label={t("envanter.pKodlari")} style={{ width: "100%" }}>
                                            <Select
                                                options={pKodlari}
                                                mode="tags"
                                                placeholder={t("genel.seciniz")}
                                                optionRender={(item) => {
                                                    return (
                                                        <>
                                                            {item["value"]} {t(`hpKodlari.${item["value"]}`)}
                                                        </>
                                                    );
                                                }}
                                                onChange={() => {
                                                    setPsecili(form.getFieldValue(["pKodlari"]));
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("envanter.kodAciklama")} style={{ marginTop: -20 }}>
                                            <Typography.Text style={{ whiteSpace: "pre-line" }}>{pBilgi}</Typography.Text>
                                        </Form.Item>
                                        <Form.Item name="casNo" label={t("envanter.casNo")}>
                                            <Input placeholder={t("envanter.casNoHint")} />
                                        </Form.Item>
                                        <Form.Item name="unID" label={t("envanter.unID")} style={{ width: "100%" }}>
                                            <Space.Compact style={{ width: "100%", alignItems: "center" }}>
                                                <Input
                                                    style={{ width: 70, minWidth: 70, marginRight: 10 }}
                                                    readOnly
                                                    value={envUnID ? envUnID.substring(0, 4) : null}
                                                />
                                                <Text>{envUnID ? t(`dg.${envUnID}`) : null}</Text>
                                            </Space.Compact>
                                        </Form.Item>
                                        <Form.Item label={t("envanter.ttn")}>
                                            <Space.Compact style={{ width: "100%", alignItems: "center" }}>
                                                <Input style={{ width: 70, minWidth: 70, marginRight: 10 }} readOnly value={envTtn} />
                                                <Text>{envTtn ? t(`ttnHin.${envTtn}`) : null}</Text>
                                            </Space.Compact>
                                        </Form.Item>
                                        <Form.Item label={t("envanter.ergNo")}>
                                            <Space.Compact style={{ width: "100%", alignItems: "center" }}>
                                                <Input style={{ width: 70, minWidth: 70, marginRight: 10 }} readOnly value={envErgNo} />
                                                <Text>{envErgNo ? t(`ergBaslik.${envErgNo}`) : null}</Text>
                                            </Space.Compact>
                                        </Form.Item>
                                        <Form.Item name="eacNo" label={t("envanter.eacNo")}>
                                            <Input placeholder={t("envanter.eacNoHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("envanter.tabResim"),
                                children: (
                                    <>
                                        <GhsListesi name="ghsEtiket" label={t("envanter.ghsEtiket")} />
                                        <AdrListesi name="adrEtiket" label={t("envanter.adrEtiket")} />

                                        <Divider>{t("envanter.nfpa")}</Divider>
                                        <Form.Item name="nfpaSaglik" label={t("envanter.nfpaSaglik")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="nfpaSaglik0" value="0">
                                                    {t("nfpaSaglik.0")}
                                                </Select.Option>
                                                <Select.Option key="nfpaSaglik1" value="1">
                                                    {t("nfpaSaglik.1")}
                                                </Select.Option>
                                                <Select.Option key="nfpaSaglik2" value="2">
                                                    {t("nfpaSaglik.2")}
                                                </Select.Option>
                                                <Select.Option key="nfpaSaglik3" value="3">
                                                    {t("nfpaSaglik.3")}
                                                </Select.Option>
                                                <Select.Option key="nfpaSaglik4" value="4">
                                                    {t("nfpaSaglik.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="nfpaYanicilik" label={t("envanter.nfpaYanicilik")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="nfpaYanicilik0" value="0">
                                                    {t("nfpaYanicilik.0")}
                                                </Select.Option>
                                                <Select.Option key="nfpaYanicilik1" value="1">
                                                    {t("nfpaYanicilik.1")}
                                                </Select.Option>
                                                <Select.Option key="nfpaYanicilik2" value="2">
                                                    {t("nfpaYanicilik.2")}
                                                </Select.Option>
                                                <Select.Option key="nfpaYanicilik3" value="3">
                                                    {t("nfpaYanicilik.3")}
                                                </Select.Option>
                                                <Select.Option key="nfpaYanicilik4" value="4">
                                                    {t("nfpaYanicilik.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="nfpaReaktivite" label={t("envanter.nfpaReaktivite")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="nfpaReaktivite0" value="0">
                                                    {t("nfpaReaktivite.0")}
                                                </Select.Option>
                                                <Select.Option key="nfpaReaktivite1" value="1">
                                                    {t("nfpaReaktivite.1")}
                                                </Select.Option>
                                                <Select.Option key="nfpaReaktivite2" value="2">
                                                    {t("nfpaReaktivite.2")}
                                                </Select.Option>
                                                <Select.Option key="nfpaReaktivite3" value="3">
                                                    {t("nfpaReaktivite.3")}
                                                </Select.Option>
                                                <Select.Option key="nfpaReaktivite4" value="4">
                                                    {t("nfpaReaktivite.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="nfpaOzelnot" label={t("envanter.nfpaOzelnot")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="nfpaOzelnot" value="">
                                                    {" "}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelALK" value="ALK">
                                                    {t("nfpaOzelnot.ALK")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelCOR" value="COR">
                                                    {t("nfpaOzelnot.COR")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelW" value="W">
                                                    {t("nfpaOzelnot.W")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelOX" value="OX">
                                                    {t("nfpaOzelnot.OX")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelSA" value="SA">
                                                    {t("nfpaOzelnot.SA")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelP" value="P">
                                                    {t("nfpaOzelnot.P")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelR" value="R">
                                                    {t("nfpaOzelnot.R")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelPOI" value="POI">
                                                    {t("nfpaOzelnot.POI")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelBIO" value="BIO">
                                                    {t("nfpaOzelnot.BIO")}
                                                </Select.Option>
                                                <Select.Option key="nfpaOzelCRYO" value="CRYO">
                                                    {t("nfpaOzelnot.CRYO")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Divider>{t("envanter.hmis")}</Divider>
                                        <Form.Item name="hmisSaglik" label={t("envanter.hmisSaglik")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="hmisSaglik0" value="0">
                                                    {t("hmisSaglik.0")}
                                                </Select.Option>
                                                <Select.Option key="hmisSaglik1" value="1">
                                                    {t("hmisSaglik.1")}
                                                </Select.Option>
                                                <Select.Option key="hmisSaglik2" value="2">
                                                    {t("hmisSaglik.2")}
                                                </Select.Option>
                                                <Select.Option key="hmisSaglik3" value="3">
                                                    {t("hmisSaglik.3")}
                                                </Select.Option>
                                                <Select.Option key="hmisSaglik4" value="4">
                                                    {t("hmisSaglik.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="hmisYanicilik" label={t("envanter.hmisYanicilik")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="hmisYanicilik0" value="0">
                                                    {t("hmisYanicilik.0")}
                                                </Select.Option>
                                                <Select.Option key="hmisYanicilik1" value="1">
                                                    {t("hmisYanicilik.1")}
                                                </Select.Option>
                                                <Select.Option key="hmisYanicilik2" value="2">
                                                    {t("hmisYanicilik.2")}
                                                </Select.Option>
                                                <Select.Option key="hmisYanicilik3" value="3">
                                                    {t("hmisYanicilik.3")}
                                                </Select.Option>
                                                <Select.Option key="hmisYanicilik4" value="4">
                                                    {t("hmisYanicilik.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="hmisReaktivite" label={t("envanter.hmisReaktivite")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="hmisReaktivite0" value="0">
                                                    {t("hmisReaktivite.0")}
                                                </Select.Option>
                                                <Select.Option key="hmisReaktivite1" value="1">
                                                    {t("hmisReaktivite.1")}
                                                </Select.Option>
                                                <Select.Option key="hmisReaktivite2" value="2">
                                                    {t("hmisReaktivite.2")}
                                                </Select.Option>
                                                <Select.Option key="hmisReaktivite3" value="3">
                                                    {t("hmisReaktivite.3")}
                                                </Select.Option>
                                                <Select.Option key="hmisReaktivite4" value="4">
                                                    {t("hmisReaktivite.4")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="hmisKkd" label={t("envanter.hmisKkd")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="hmisKkd" value="">
                                                    {" "}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdA" value="A">
                                                    {t("hmisKkd.A")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdB" value="B">
                                                    {t("hmisKkd.B")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdC" value="C">
                                                    {t("hmisKkd.C")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdD" value="D">
                                                    {t("hmisKkd.D")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdE" value="E">
                                                    {t("hmisKkd.E")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdF" value="F">
                                                    {t("hmisKkd.F")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdG" value="G">
                                                    {t("hmisKkd.G")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdH" value="H">
                                                    {t("hmisKkd.H")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdI" value="I">
                                                    {t("hmisKkd.I")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdJ" value="J">
                                                    {t("hmisKkd.J")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdK" value="K">
                                                    {t("hmisKkd.K")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdN" value="N">
                                                    {t("hmisKkd.N")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdO" value="O">
                                                    {t("hmisKkd.O")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdP" value="P">
                                                    {t("hmisKkd.P")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdQ" value="Q">
                                                    {t("hmisKkd.Q")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdR" value="R">
                                                    {t("hmisKkd.R")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdS" value="S">
                                                    {t("hmisKkd.S")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdT" value="T">
                                                    {t("hmisKkd.T")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdU" value="U">
                                                    {t("hmisKkd.U")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdW" value="W">
                                                    {t("hmisKkd.W")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdY" value="Y">
                                                    {t("hmisKkd.Y")}
                                                </Select.Option>
                                                <Select.Option key="hmisKkdZ" value="Z">
                                                    {t("hmisKkd.Z")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "4",
                                label: t("envanter.tabERG"),
                                children: (
                                    <>
                                        <Divider>{t("envanter.divTehlike")}</Divider>
                                        <Form.Item name="ptSaglik" label={t("envanter.ptSaglik")}>
                                            <Input.TextArea placeholder={t("envanter.ptSaglikHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="ptYangin" label={t("envanter.ptYangin")}>
                                            <Input.TextArea placeholder={t("envanter.ptYanginHint")} autoSize />
                                        </Form.Item>

                                        <Divider>{t("envanter.divGuvenlik")}</Divider>
                                        <Form.Item name="kamuGuvenligi" label={t("envanter.kamuGuvenligi")}>
                                            <Input.TextArea placeholder={t("envanter.kamuGuvenligiHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="koruyucuEkipman" label={t("envanter.koruyucuEkipman")}>
                                            <Input.TextArea placeholder={t("envanter.koruyucuEkipmanHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="tahliyeBasta" label={t("envanter.tahliyeBasta")}>
                                            <Input.TextArea placeholder={t("envanter.tahliyeBastaHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="tahliyeSizinti" label={t("envanter.tahliyeSizinti")}>
                                            <Input.TextArea placeholder={t("envanter.tahliyeSizintiHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="tahliyeYangin" label={t("envanter.tahliyeYangin")}>
                                            <Input.TextArea placeholder={t("envanter.tahliyeYanginHint")} autoSize />
                                        </Form.Item>

                                        <Divider>{t("envanter.divMudahale")}</Divider>
                                        <Form.Item name="mudahaleYangin" label={t("envanter.mudahaleYangin")}>
                                            <Input.TextArea placeholder={t("envanter.mudahaleYanginHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="mudahaleSizinti" label={t("envanter.mudahaleSizinti")}>
                                            <Input.TextArea placeholder={t("envanter.mudahaleSizintiHint")} autoSize />
                                        </Form.Item>
                                        <Form.Item name="ilkYardim" label={t("envanter.ilkYardim")}>
                                            <Input.TextArea placeholder={t("envanter.ilkYardimHint")} autoSize />
                                        </Form.Item>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Form>
            </Content>
        </Layout>
    );
}

export default EnvanterGuncelle;
