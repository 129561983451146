import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";

export const useBolumEkle = () => {
    const { firmaID } = useGetUserInfo();
    const { accessToken } = useGetToken();
    const bolumEkle = async ({ bolumIsmi }) => {
        fetch(`http://89.19.24.18:3001/Bolum/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                bolumIsmi: bolumIsmi ? bolumIsmi : "",
                firmaID: firmaID,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { bolumEkle };
};

export const useBolumGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const bolumGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Bolum/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { bolumGuncelle };
};

export const useBolumSil = (id) => {
    const { accessToken } = useGetToken();
    const bolumSil = async () => {
        fetch(`http://89.19.24.18:3001/bolum/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { bolumSil };
};

export const useBolumleriGetir = () => {
    const { accessToken } = useGetToken();
    const [bolumler, setBolumler] = useState([]);
    const bolumleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Bolum", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBolumler(data);
            });

        return () => bolumler;
    };
    useEffect(() => {
        bolumleriGetir();
    }, []);

    return { bolumler };
};

export const useBolumuGetir = (id) => {
    const { accessToken } = useGetToken();
    const [bolum, setBolum] = useState([]);
    const bolumuGetir = async () => {
        fetch(`http://89.19.24.18:3001/Bolum/byId/${id}`, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBolum(data);
            });

        return () => bolum;
    };

    useEffect(() => {
        bolumuGetir();
    }, []);

    return { bolum };
};
