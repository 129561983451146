import { Form, Input, Layout, notification } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useRolEkle } from "../hooks/useRol";

const { Content } = Layout;

function RolEkle() {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { rolEkle } = useRolEkle();

    const onSubmit = (e) => {
        rolEkle({
            isim: e.isim,
            yetkiler: "",
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="rolEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                >
                    <Form.Item name="isim" label={t("rol.isim")} rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}>
                        <Input placeholder={t("rol.isimHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default RolEkle;
