import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useEkipmanEkle = () => {
    const { accessToken } = useGetToken();
    const ekipmanEkle = async (e) => {
        fetch(`http://89.19.24.18:3001/Ekipman/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("ekipman add cevap : ", data);
            });
    };

    return { ekipmanEkle };
};

export const useEkipmanGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const ekipmanGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Ekipman/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("ekipman update cevap : ", data);
            });
    };
    return { ekipmanGuncelle };
};

export const useEkipmanSil = (id) => {
    const { accessToken } = useGetToken();
    const ekipmanSil = async () => {
        fetch(`http://89.19.24.18:3001/Ekipman/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("ekipman delete cevap : ", data);
            });
    };
    return { ekipmanSil };
};

export const useEkipmanlariGetir = () => {
    const { accessToken } = useGetToken();
    const [ekipmanlar, setEkipmanlar] = useState([]);
    const ekipmanlariGetir = async () => {
        fetch("http://89.19.24.18:3001/Ekipman", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEkipmanlar(data);
            });

        return () => ekipmanlar;
    };
    useEffect(() => {
        ekipmanlariGetir();
    }, []);

    return { ekipmanlar };
};

export const useEkipmaniGetir = (id) => {
    const { accessToken } = useGetToken();
    const [ekipman, setEkipman] = useState([]);
    const ekipmaniGetir = async () => {
        fetch(`http://89.19.24.18:3001/Ekipman/byId/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEkipman(data);
            });

        return () => ekipman;
    };

    useEffect(() => {
        ekipmaniGetir();
    }, []);

    return { ekipman };
};
