import React, { useMemo } from "react";
import { Rect, Svg } from "@react-pdf/renderer";
import { getMatrix } from "qr-code-generator-lib";

/* level
  L = 0,
  M = 1,
  Q = 2,
  H = 3
*/
const Karekod = ({ url, level = 3, width = 100, foreground = "#333", background = "#fff" }) => {
    const matrix = useMemo(() => getMatrix(url), level, [url, level]);
    const factor = useMemo(() => width / matrix.length, [matrix, width]);

    return (
        <Svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
            {matrix.map((row, x) =>
                row.map((cell, y) => (
                    <Rect x={x * factor} y={y * factor} width={1 * factor} height={1 * factor} fill={cell ? foreground : background} />
                ))
            )}
        </Svg>
    );
};

export default Karekod;
