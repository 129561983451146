import { Form, Button, DatePicker, Input, Layout, notification, Popconfirm, Radio, Select, Space, Spin, Tabs, Tooltip } from "antd";
import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useKisileriGetir } from "../hooks/useKisi";
import { useRiskiGetir, useRiskGuncelle, useRiskSil } from "../hooks/useRisk";
import BolumSelect from "../components/BolumSelect";

const { Content } = Layout;

function RiskGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const { kisiler } = useKisileriGetir();
    const [riskEkibi, setRiskEkibi] = useState([]);

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [rutinmi, setRutinMi] = useState();
    const [isKazasi, setIsKazasi] = useState();
    const [ramakKala, setRamakKala] = useState();

    const { risk } = useRiskiGetir(id);
    const { riskGuncelle } = useRiskGuncelle(id);
    const { riskSil } = useRiskSil(id);

    const riskKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="riskKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const riskSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="riskSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                riskSil(id);
                navigate("/riskList");
            }}
            style={{ width: 400 }}
        >
            <Button key="riskSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (kisiler) {
            const dropdownItemsPerson = kisiler.map((item) => ({ value: String(item.kisiID), label: `${item.isim} ${item.soyad}` }));
            setRiskEkibi(dropdownItemsPerson);
        }
    }, [kisiler]);

    useEffect(() => {
        if (risk) {
            form.setFieldsValue({
                anaFaaliyet: risk.anaFaaliyet,
                bolumID: risk.bolumID,
                ekipUyeleri: risk.ekipUyeleri,
                etki: risk.etki,
                frekans: risk.frekans,
                ihtimal: risk.ihtimal,
                isKazasi: risk.isKazasi,
                kalanEtki: risk.kalanEtki,
                kalanFrekans: risk.kalanFrekans,
                kalanIhtimal: risk.kalanIhtimal,
                kalanPuan: risk.kalanPuan,
                kapanis: risk.kapanis,
                kapanisNotu: risk.kapanisNotu,
                maruzKalan: risk.maruzKalan,
                mevcutDurum: risk.mevcutDurum,
                mevzuat: risk.mevzuat,
                onlemler: risk.onlemler,
                puan: risk.puan,
                ramakKala: risk.ramakKala,
                riskinTanimi: risk.riskinTanimi,
                rutinMi: risk.rutinMi,
                tehlikeKaynagi: risk.tehlikeKaynagi,
                tehlikeliFaaliyet: risk.tehlikeliFaaliyet,
                yer: risk.yer,
            });
            setLoading(false);
        }
    }, [risk]);

    const onSubmit = (e) => {
        setIsPending(true);
        console.log("form bilgisi : ", e);
        riskGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="riskGuncelleKmyHeader"
                        caller="sayfaBaslik.riskGuncelle"
                        buttons={[riskKaydetButon, riskSilButon]}
                        backURL="/riskList"
                    />
                    {contextHolder}
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        style={{ margin: 20 }}
                        items={[
                            {
                                key: "1",
                                label: t("risk.tabTehlike"),
                                children: (
                                    <>
                                        <BolumSelect name="bolumID" label={t("risk.bolum")} hint={t("risk.bolumHint")} />
                                        <Form.Item name="yer" label={t("risk.yer")}>
                                            <Input placeholder={t("risk.yerHint")} />
                                        </Form.Item>
                                        <Form.Item name="anaFaaliyet" label={t("risk.anaFaaliyet")}>
                                            <Input placeholder={t("risk.anaFaaliyet")} />
                                        </Form.Item>
                                        <Form.Item name="tehlikeliFaaliyet" label={t("risk.tehlikeliFaaliyet")}>
                                            <Input placeholder={t("risk.tehlikeliFaaliyetHint")} />
                                        </Form.Item>
                                        <Form.Item name="tehlikeKaynagi" label={t("risk.tehlikeKaynagi")}>
                                            <Input placeholder={t("risk.tehlikeKaynagiHint")} />
                                        </Form.Item>
                                        <Form.Item name="maruzKalan" label={t("risk.maruzKalan")}>
                                            <Input placeholder={t("risk.maruzKalanHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("risk.tabMevcut"),
                                children: (
                                    <>
                                        <Form.Item name="mevcutDurum" label={t("risk.mevcutDurum")}>
                                            <Input placeholder={t("risk.mevcutDurumHint")} />
                                        </Form.Item>
                                        <Form.Item name="onlemler" label={t("risk.onlemler")}>
                                            <Input placeholder={t("risk.onlemlerHint")} />
                                        </Form.Item>
                                        <Form.Item name="mevzuat" label={t("risk.mevzuat")}>
                                            <Input placeholder={t("risk.mevzuatHint")} />
                                        </Form.Item>
                                        <Form.Item name="rutinMi" label={t("risk.rutinMi")}>
                                            <Radio.Group
                                                value={rutinmi}
                                                onChange={(value) => {
                                                    setRutinMi(value);
                                                }}
                                            >
                                                <Radio.Button value="X">{t("genel.x")}</Radio.Button>
                                                <Radio.Button value="R">{t("rutinMi.R")}</Radio.Button>
                                                <Radio.Button value="D">{t("rutinMi.D")}</Radio.Button>
                                                <Radio.Button value="A">{t("rutinMi.A")}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item name="isKazasi" label={t("risk.isKazasi")}>
                                            <Radio.Group
                                                value={isKazasi}
                                                onChange={(value) => {
                                                    setIsKazasi(value);
                                                }}
                                            >
                                                <Radio.Button value="X">{t("genel.x")}</Radio.Button>
                                                <Radio.Button value="E">{t("genel.evet")}</Radio.Button>
                                                <Radio.Button value="H">{t("genel.hayir")}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item name="ramakKala" label={t("risk.ramakKala")}>
                                            <Radio.Group
                                                value={ramakKala}
                                                onChange={(value) => {
                                                    setRamakKala(value);
                                                }}
                                            >
                                                <Radio.Button value="X">{t("genel.x")}</Radio.Button>
                                                <Radio.Button value="E">{t("genel.evet")}</Radio.Button>
                                                <Radio.Button value="H">{t("genel.hayir")}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("risk.tabRisk"),
                                children: (
                                    <>
                                        <Form.Item name="riskinTanimi" label={t("risk.riskinTanimi")}>
                                            <Input placeholder={t("risk.riskinTanimiHint")} />
                                        </Form.Item>
                                        <Form.Item name="ihtimal" label={t("risk.ihtimal")}>
                                            <Select placeholder={t("risk.ihtimalHint")}>
                                                <Select.Option value="0.2">0.2 - Neredeyse imkansız</Select.Option>
                                                <Select.Option value="0.5">0.5 - Zayıf İhtimal</Select.Option>
                                                <Select.Option value="1">1 - Oldukça düşük ihtimal</Select.Option>
                                                <Select.Option value="3">3 - Nadir, fakat olabilir</Select.Option>
                                                <Select.Option value="6">6 - Olabilir</Select.Option>
                                                <Select.Option value="10">10 - Çok kuvvetli ihtimal</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="frekans" label={t("risk.frekans")}>
                                            <Select placeholder={t("risk.frekansHint")}>
                                                <Select.Option value="0.5">0.5 - Çok Nadir (Yılda 1 veya daha az)</Select.Option>
                                                <Select.Option value="1">1 - Oldukça Nadir (Yılda 1-2 kez)</Select.Option>
                                                <Select.Option value="2">2 - Nadir (Ayda 1-2 kez)</Select.Option>
                                                <Select.Option value="3">3 - Ara Sıra (Haftada 1-2 kez)</Select.Option>
                                                <Select.Option value="6">6 - Sıklıkla (Günde 1-2 kez)</Select.Option>
                                                <Select.Option value="10">10 - Sürekli (Saatte 1 kereden fazla)</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="etki" label={t("risk.etki")}>
                                            <Select placeholder={t("risk.etkiHint")}>
                                                <Select.Option value="1">
                                                    1 - Önemsiz : Hafif, zararsız, maddi zarar veya şikayet yok. Ucuz atlatma
                                                </Select.Option>
                                                <Select.Option value="3">
                                                    3 - Önemli : Küçük hasar, düşük iş kaybı, ilk yardım, kabul edilebilir maddi zarar. küçük çevre
                                                    zararı, dahili müdahale
                                                </Select.Option>
                                                <Select.Option value="7">
                                                    7 - Ciddi : önemli zarar, dış yardım/tedavi, iş günü kaybı. önemli çevre zararı, ürün ve hizmet
                                                    gerçekleştirmeyi engellemeyen sistemsel hatalar, Müşteri Şikayeti
                                                </Select.Option>
                                                <Select.Option value="15">
                                                    15 - çok Ciddi : Sakatlık/uzuv kaybı, tutuşma. Bertarafı mümkün sınırlı çevre hasarı. ürün ve
                                                    hizmet gerçekleştirmeyi engelleyen sistem hataları. Haklı Müşteri Şikayeti. Maddi kayıplar
                                                </Select.Option>
                                                <Select.Option value="40">
                                                    40 - çok Kötü : ölüm, tam malüliyet, yangın. Geri dönüşü mümkün olmayan sınırlı çevresel hasar,
                                                    Şirket imajı zedelenmesi, Müşteri Kaybı
                                                </Select.Option>
                                                <Select.Option value="100">
                                                    100 - Felaket : çoklu ölüm, çevre felaketi, patlama, Geri dönüşü olmayan imaj kaybı, Geri dönüşü
                                                    olmayan müşteri kaybı, çok yüksek ceza / Resmi kurum cezaları
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="puan" label={t("risk.puan")}>
                                            <Input placeholder={t("risk.puanHint")} disabled={true} />
                                        </Form.Item>
                                        <Form.Item name="ekipUyeleri" label={t("risk.ekipUyeleri")}>
                                            <Select mode="multiple" placeholder={t("risk.ekipUyeleri")}>
                                                {riskEkibi.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "4",
                                label: t("risk.tabAksiyon"),
                                children: <></>,
                            },
                            {
                                key: "5",
                                label: t("risk.tabArtik"),
                                children: (
                                    <>
                                        <Form.Item name="kalanIhtimal" label={t("risk.kalanIhtimal")}>
                                            <Select placeholder={t("risk.kalanIhtimalHint")}>
                                                <Select.Option value="0.2">0.2 - Neredeyse imkansız</Select.Option>
                                                <Select.Option value="0.5">0.5 - Zayıf İhtimal</Select.Option>
                                                <Select.Option value="1">1 - Oldukça düşük ihtimal</Select.Option>
                                                <Select.Option value="3">3 - Nadir, fakat olabilir</Select.Option>
                                                <Select.Option value="6">6 - Olabilir</Select.Option>
                                                <Select.Option value="10">10 - Çok kuvvetli ihtimal</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="kalanFrekans" label={t("risk.kalanFrekans")}>
                                            <Select placeholder={t("risk.kalanFrekansHint")}>
                                                <Select.Option value="0.5">0.5 - Çok Nadir (Yılda 1 veya daha az)</Select.Option>
                                                <Select.Option value="1">1 - Oldukça Nadir (Yılda 1-2 kez)</Select.Option>
                                                <Select.Option value="2">2 - Nadir (Ayda 1-2 kez)</Select.Option>
                                                <Select.Option value="3">3 - Ara Sıra (Haftada 1-2 kez)</Select.Option>
                                                <Select.Option value="6">6 - Sıklıkla (Günde 1-2 kez)</Select.Option>
                                                <Select.Option value="10">10 - Sürekli (Saatte 1 kereden fazla)</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="kalanEtki" label={t("risk.kalanEtki")}>
                                            <Select placeholder={t("risk.kalanEtkiHint")}>
                                                <Select.Option value="1">
                                                    1 - Önemsiz : Hafif, zararsız, maddi zarar veya şikayet yok. Ucuz atlatma
                                                </Select.Option>
                                                <Select.Option value="3">
                                                    3 - Önemli : Küçük hasar, düşük iş kaybı, ilk yardım, kabul edilebilir maddi zarar. küçük çevre
                                                    zararı, dahili müdahale
                                                </Select.Option>
                                                <Select.Option value="7">
                                                    7 - Ciddi : önemli zarar, dış yardım/tedavi, iş günü kaybı. önemli çevre zararı, ürün ve hizmet
                                                    gerçekleştirmeyi engellemeyen sistemsel hatalar, Müşteri Şikayeti
                                                </Select.Option>
                                                <Select.Option value="15">
                                                    15 - çok Ciddi : Sakatlık/uzuv kaybı, tutuşma. Bertarafı mümkün sınırlı çevre hasarı. ürün ve
                                                    hizmet gerçekleştirmeyi engelleyen sistem hataları. Haklı Müşteri Şikayeti. Maddi kayıplar
                                                </Select.Option>
                                                <Select.Option value="40">
                                                    40 - çok Kötü : ölüm, tam malüliyet, yangın. Geri dönüşü mümkün olmayan sınırlı çevresel hasar,
                                                    Şirket imajı zedelenmesi, Müşteri Kaybı
                                                </Select.Option>
                                                <Select.Option value="100">
                                                    100 - Felaket : çoklu ölüm, çevre felaketi, patlama, Geri dönüşü olmayan imaj kaybı, Geri dönüşü
                                                    olmayan müşteri kaybı, çok yüksek ceza / Resmi kurum cezaları
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="kalanPuan" label={t("risk.kalanPuan")}>
                                            <Input placeholder={t("risk.kalanPuanHint")} disabled={true} />
                                        </Form.Item>
                                        <Form.Item name="kapanis" label={t("risk.kapanis")}>
                                            <Input disabled={true} />
                                        </Form.Item>
                                        <Form.Item name="kapanisNotu" label={t("risk.kapanisNotu")}>
                                            <Input placeholder={t("risk.kapanisNotuHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Form>
            </Content>
        </Layout>
    );
}

export default RiskGuncelle;
