import { Form, Button, DatePicker, Input, notification, Popconfirm, Spin } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { CheckOutlined } from "@ant-design/icons";
import { useAksiyonGetir, useAksiyonGuncelle, useAksiyonSil } from "../hooks/useAksiyon";
import SahaDegerleri from "../components/SahaDegerleri";
import BolumSelect from "../components/BolumSelect";
import KisiSelect from "../components/KisiSelect";

const { TextArea } = Input;

function AksiyonGuncelle(props) {
    let id = props ? (props.aksiyonID ? props.aksiyonID : null) : null;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [aksiyonDurumu, setAksiyonDurumu] = useState();

    const { aksiyon } = useAksiyonGetir(id);
    const { aksiyonGuncelle } = useAksiyonGuncelle(id);
    const { aksiyonSil } = useAksiyonSil(id);

    const aksiyonSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="aksiyonSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                aksiyonSil(id);
                navigate("/aksiyonList");
            }}
            style={{ width: 400 }}
        >
            <Button key="aksiyonSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (aksiyon) {
            form.setFieldsValue({
                aksiyonTipi: aksiyon.aksiyonTipi,
                bolumID: aksiyon.bolumID,
                konum: aksiyon.konum,
                hedefTarih: aksiyon.hedefTarih ? dayjs(aksiyon.hedefTarih) : null,
                aciklama: aksiyon.aciklama,
                kapaliMi: aksiyon.kapaliMi,
                kapanisZamani: aksiyon.kapanisZamani ? dayjs(aksiyon.kapanisZamani) : null,
                kapanisNotu: aksiyon.kapanisNotu,
                sorumlular: aksiyon.sorumlular,
            });
            setAksiyonDurumu(aksiyon.aksiyonDurumu);
            setLoading(false);
            console.log("durum :", aksiyon, aksiyonDurumu);
        }
    }, [aksiyon]);

    const onSubmit = (e) => {
        aksiyonGuncelle({ id, e });
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <SahaDegerleri name="aksiyonTipi" id="aksiyon.aksiyonTipi" label={t("aksiyon.aksiyonTipi")} />
            <BolumSelect name="bolumID" label={t("aksiyon.bolum")} hint={t("aksiyon.bolumHint")} />
            <SahaDegerleri name="konum" id="aksiyon.konum" label={t("aksiyon.konum")} hint={t("aksiyon.konumHint")} />
            <Form.Item name="hedefTarih" label={t("aksiyon.hedefTarih")}>
                <DatePicker />
            </Form.Item>
            <Form.Item name="aciklama" label={t("aksiyon.aciklama")}>
                <TextArea placeholder={t("aksiyon.aciklamaHint")} autoSize={true} readOnly />
            </Form.Item>
            <KisiSelect name="sorumlular" label={t("aksiyon.sorumlular")} mode="tags" />

            <Button key="aksiyonKapatButon" type="primary" onClick={() => setAksiyonDurumu("K")} icon={<CheckOutlined />}>
                {t(`aksiyon.${aksiyonDurumu}`)}
            </Button>

            <Form.Item name="kapanisZamani" label={t("aksiyon.kapanisZamani")}>
                <DatePicker showTime disabled />
            </Form.Item>
            <Form.Item name="kapanisNotu" label={t("aksiyon.kapanisNotu")}>
                <Input placeholder={t("aksiyon.kapanisNotuHint")} />
            </Form.Item>
        </Form>
    );
}

export default AksiyonGuncelle;
