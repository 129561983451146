import { Form, Button, DatePicker, Input, Layout, notification, Popconfirm, Spin } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useAcilDurumPlaniGetir, useAcilDurumPlaniKaydet, useAcilDurumPlaniSil } from "../hooks/useAcilDurumPlani";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import KisiSelect from "../components/KisiSelect";

const { Content } = Layout;
const { TextArea } = Input;

function AcilDurumPlani() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [api, contextHolder] = notification.useNotification();

    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [durum, setDurum] = useState();

    const { adSoyad, profilePhoto, kisiID, firmaID, firmaismi, firmaLogo, isAuth } = useGetUserInfo();
    const { acilDurumPlani } = useAcilDurumPlaniGetir(id);
    const { acilDurumPlaniSil } = useAcilDurumPlaniSil(id);
    const { acilDurumPlaniKaydet } = useAcilDurumPlaniKaydet();
    const { kayitDegisebilir, setKayitDegisebilir } = useState(false);
    const { kisiYazabilir, setKisiYazabilir } = useState(false);
    const { kisiSilebilir, setKisiSilebilir } = useState(false);

    const acilDurumPlaniKaydetButon =
        kisininYetkileri.includes("ADUPKD") || kisininYetkileri.includes("ADMIN") ? (
            <Button key="acilDurumPlaniKaydetButon" type="primary" htmlType="submit" loading={isPending}>
                {t("genel.kaydet")}
            </Button>
        ) : null;

    const acilDurumPlaniSilButon = (kisininYetkileri.includes("ADUPKS") || kisininYetkileri.includes("ADMIN")) && (
        <Popconfirm
            key="acilDurumPlaniSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                acilDurumPlaniSil(id);
                navigate("/adpList");
            }}
            style={{ width: 400 }}
        >
            <Button key="acilDurumPlaniSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (acilDurumPlani) {
            form.setFieldsValue({
                acilDurum: acilDurumPlani.acilDurum,
                hazirlamaTarihi: acilDurumPlani.hazirlamaTarihi ? dayjs(acilDurumPlani.hazirlamaTarihi) : null,
                gecerlilikTarihi: acilDurumPlani.gecerlilikTarihi ? dayjs(acilDurumPlani.gecerlilikTarihi) : null,
                hazirlayanlar: acilDurumPlani.hazirlayanlar,
                onaylayan: acilDurumPlani.onaylayan,
                onleyiciTedbirler: acilDurumPlani.onleyiciTedbirler,
                sinirlayiciTedbirler: acilDurumPlani.sinirlayiciTedbirler,
                mudahaleYontemleri: acilDurumPlani.mudahaleYontemleri,
            });
            setDurum(acilDurumPlani.durum);
            setLoading(false);
            if (kisininYetkileri.includes("ADMIN")) {
                setKisiYazabilir(true);
                setKisiSilebilir(true);
            }
            if (kisininYetkileri.includes("ADUP")) {
                setKisiYazabilir(true);
                setKisiSilebilir(true);
            }
            if (kisininYetkileri.includes("ADUPKD")) setKisiYazabilir(true);
            if (kisininYetkileri.includes("ADUPKS")) setKisiSilebilir(true);
            if (acilDurumPlani.durum == "T") {
                //taslak durumundaysa ve taslak sahibi bu kişiyse
                if (acilDurumPlani.taslakSahibi == kisiID) setKayitDegisebilir(true);
            }
        }
    }, [acilDurumPlani, kisininYetkileri]);

    const onSubmit = (e) => {
        if (id) e.kayitID = id;
        setIsPending(true);
        acilDurumPlaniKaydet(e);
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={durum == "E" || (!kisininYetkileri.includes("ADUPKD") && !kisininYetkileri.includes("ADMIN"))}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="acilDurumPlaniKmyHeader"
                        caller="sayfaBaslik.acilDurumPlani"
                        buttons={[acilDurumPlaniKaydetButon, acilDurumPlaniSilButon]}
                        backURL="/adpList"
                    />
                    {contextHolder}
                    {kisininYetkileri.includes("ADUPKD") ||
                        (kisininYetkileri.includes("ADMIN") && (
                            <Button key="acilDurumPlaniKaydetButon" type="primary" htmlType="submit" loading={isPending}>
                                {kisininYetkileri.includes("ADMIN") ? "evet" : "hayir"}
                            </Button>
                        ))}
                    <Form.Item name="hazirlamaTarihi" label={t("adp.hazirlamaTarihi")}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="gecerlilikTarihi" label={t("adp.gecerlilikTarihi")}>
                        <DatePicker />
                    </Form.Item>
                    <KisiSelect name="hazirlayanlar" label={t("adp.hazirlayanlar")} mode="tags" />
                    <KisiSelect name="onaylayan" label={t("adp.onaylayan")} />
                    <Form.Item name="acilDurum" label={t("adp.acilDurum")}>
                        <TextArea placeholder={t("adp.acilDurumHint")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="onleyiciTedbirler" label={t("adp.onleyiciTedbirler")}>
                        <TextArea placeholder={t("adp.onleyiciTedbirler")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="sinirlayiciTedbirler" label={t("adp.sinirlayiciTedbirler")}>
                        <TextArea placeholder={t("adp.sinirlayiciTedbirler")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="mudahaleYontemleri" label={t("adp.mudahaleYontemleri")}>
                        <TextArea placeholder={t("adp.mudahaleYontemleri")} autoSize={true} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default AcilDurumPlani;
