import { useGetUserInfo } from "./useGetUserInfo";
import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";

export const useKisiEkle = () => {
    const [kisi, setkisi] = useState();
    const { accessToken } = useGetToken();

    const kisiEkle = async ({ isim, soyad, email, telefonNo, TCno, firmaID }) => {
        fetch(`http://89.19.24.18:3001/Kisi/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                isim: isim ? isim : "",
                soyad: soyad ? soyad : "",
                email: email ? email : "",
                telefonNo: telefonNo ? telefonNo : "",
                TCno: TCno ? TCno : "",
                firmaID: firmaID ? firmaID : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data.body);
            });
    };

    return { kisiEkle };
};

export const useKisiGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const kisiGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Kisi/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { kisiGuncelle };
};

export const useKisiOlustur = (id, e) => {
    const { accessToken } = useGetToken();
    const kisiOlustur = async (id, e) => {
        try {
            fetch("http://89.19.24.18:3001/Login", {
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                body: JSON.stringify({
                    uid: id,
                    isim: e.isim,
                    email: e.email,
                    fotoURL: e.fotoURL,
                }),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    console.log("usekisiolustur : ", data);
                    const authInfo = {
                        kisiID: data["kisiID"],
                        adSoyad: `${data["isim"]} ${data["soyad"]}`,
                        profilePhoto: data["fotoURL"],
                        firmaID: data["firmaID"],
                        firmaismi: data["firmaismi"],
                        firmaLogo: data["firmaLogo"],
                        yetkiler: data["yetkiler"],
                    };
                    localStorage.setItem("auth", JSON.stringify(authInfo));
                });
        } catch (err) {
            console.error("olmadı : ", err);
        }
    };
    return { kisiOlustur };
};

export const useKisiyiSil = (id) => {
    const { accessToken } = useGetToken();
    const kisiyiSil = async () => {
        fetch(`http://89.19.24.18:3001/Kisi/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { kisiyiSil };
};

export const useKisileriGetir = () => {
    const { accessToken } = useGetToken();
    const [kisiler, setKisiler] = useState([]);
    const kisileriGetir = async () => {
        fetch("http://89.19.24.18:3001/Kisi", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisiler(data);
            });

        return () => kisiler;
    };
    useEffect(() => {
        kisileriGetir();
    }, []);

    return { kisiler };
};

export const useKisiyiGetir = (id) => {
    const [kisi, setKisi] = useState([]);
    const { accessToken } = useGetToken();
    const kisiyiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Kisi/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisi(data);
            });

        return () => kisi;
    };

    useEffect(() => {
        kisiyiGetir();
    }, []);

    return { kisi };
};

export const useKisininYetkileriniGetir = () => {
    const [kisininYetkileri, setKisininYetkileri] = useState([]);
    const { accessToken } = useGetToken();
    const kisininYetkileriniGetir = async () => {
        fetch(`http://89.19.24.18:3001/Kisi/yetki`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisininYetkileri(data);
                console.log("yetkiler : ", data);
            });

        return () => kisininYetkileri;
    };

    useEffect(() => {
        kisininYetkileriniGetir();
    }, []);

    return { kisininYetkileri };
};

export const useKisininFirmalariniGetir = () => {
    const [kisininFirmalari, setKisininFirmalari] = useState([]);
    const { accessToken } = useGetToken();
    const kisininFirmalariniGetir = async () => {
        fetch(`http://89.19.24.18:3001/Kisi/firmalar`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisininFirmalari(data);
            });

        return () => kisininFirmalari;
    };

    useEffect(() => {
        kisininFirmalariniGetir();
    }, []);

    return { kisininFirmalari };
};
