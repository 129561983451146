import { Form, Button, Input, Layout, List, message, Modal, notification, Popconfirm, Select, Space, Spin, Tabs, Tag, Upload } from "antd";
import { LoadingOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
//import EditorToolbar, { modules, formats } from "../components/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "react-phone-number-input/style.css";
import { useKursuGetir, useKursGuncelle, useKursSil } from "../hooks/useKurs";
import { useKursBolumleriniGetir } from "../hooks/useKursBolum";
import { useKursSorulariniGetir } from "../hooks/useKursSoru";
import KmyHeader from "./KmyHeader";

import ImgCrop from "antd-img-crop";
import KursBolumEkle from "./KursBolumEkle";
import KursSoruEkle from "./KursSoruEkle";

const { Content } = Layout;

function KursGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const { kurs } = useKursuGetir(id);
    const [loading, setLoading] = useState(true);
    const [resimURL, setImageURL] = useState();
    const [kursBolumEkleModalOpen, setKursBolumEkleModalOpen] = useState(false);
    const [kursSoruEkleModalOpen, setKursSoruEkleModalOpen] = useState(false);

    const { kursGuncelle } = useKursGuncelle(id);
    const { kursSil } = useKursSil(id);
    const { kursBolumleri } = useKursBolumleriniGetir(id);
    const { kursSorulari } = useKursSorulariniGetir(id);

    const saveKursButton = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const deleteKursButton = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                kursSil(id);
                navigate("/kursList");
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (kurs.kursIsmi) {
            form.setFieldsValue({
                kursIsmi: kurs.kursIsmi,
                kursDetayi: kurs.kursDetayi,
                kategori: kurs.kategori,
            });
            setImageURL(kurs.resimURL);
            setLoading(false);
        }
    }, [kurs]);

    const onSubmit = (e) => {
        setIsPending(true);
        kursGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydetd"), placement: "topRight" });
    };

    // resim yükleme kısmı
    const beforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isLt10M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            setLoading(false);
            console.log("image upload done");
        }
    };

    const imageUpload = async ({ onError, onSuccess, file }) => {
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("doc", file, `cover.${fileExt}`);
        const createXHR = () => new XMLHttpRequest();
        fetch(`http://89.19.24.18:3001/Kurss/${id}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                console.log(res, "res");
            });

        setLoading(false);
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader caller="sayfaBaslik.kursGuncelle" buttons={[saveKursButton, deleteKursButton]} backURL="/kursList" />
                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", margin: 20, justifyContent: "center" }}>
                        <ImgCrop
                            showGrid="true"
                            rotationSlider="true"
                            modalTitle="Resmi düzenle"
                            modalCancel={t("genel.vazgec")}
                            modalOk={t("genel.kaydet")}
                            aspect={16 / 9}
                            cropShape="rect"
                        >
                            <Upload
                                name="resimURL"
                                accept=".jpg, .jpeg, .png"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={imageUpload}
                                beforeUpload={beforeUpload}
                                onChange={handleUploadChange}
                            >
                                {resimURL ? (
                                    <img src={`${resimURL}`} alt="Kurs image" style={{ width: 320, height: 180 }} />
                                ) : (
                                    <button style={{ border: 1, background: "none" }} type="button">
                                        {loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                                        <div style={{ marginTop: 8, marginBottom: -20, cursor: "pointer" }}>Eğitim Kapak Resmi</div>
                                    </button>
                                )}
                            </Upload>
                        </ImgCrop>
                    </Space>
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        style={{ marginTop: 20 }}
                        items={[
                            {
                                key: "1",
                                label: t("kurs.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item
                                            name="kursIsmi"
                                            label={t("kurs.kursIsmi")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Input placeholder={t("kurs.kursIsmiHint")} />
                                        </Form.Item>
                                        <Form.Item name="kategori" label={t("kurs.kategori")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="ADR">ADR</Select.Option>
                                                <Select.Option value="ISG">İSG</Select.Option>
                                                <Select.Option value="CEV">Çevre</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="kursDetayi"
                                            label={t("kurs.kursDetayi")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <ReactQuill placeholder={t("kurs.kursDetayiHint")} style={{ backgroundColor: "white" }} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("kurs.tabBolum"),
                                children: (
                                    <>
                                        <Form.Item name="bolumler" label={t("kurs.bolumler")}>
                                            <List
                                                footer={
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            setKursBolumEkleModalOpen(true);
                                                        }}
                                                    >
                                                        {t("kursBolum.bolumEkle")}
                                                    </Button>
                                                }
                                                bordered
                                                dataSource={kursBolumleri}
                                                renderItem={(item) => (
                                                    <List.Item key={item.kursBolumID}>
                                                        <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                                            <Space>
                                                                {1 === 1 && (
                                                                    <Button
                                                                        type="text"
                                                                        icon={<EditOutlined />}
                                                                        onClick={() => {
                                                                            navigate(`/kursBolumGuncelle/${item.id}`);
                                                                        }}
                                                                    />
                                                                )}
                                                                {item.kursBolumIsmi}
                                                            </Space>
                                                            {item.yayinlandiMi ? (
                                                                <Tag color="green">{t("genel.yayinlandi")}</Tag>
                                                            ) : (
                                                                <Tag>{t("genel.yayinlanmadi")}</Tag>
                                                            )}
                                                        </Space>
                                                    </List.Item>
                                                )}
                                            />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("kurs.tabSorular"),
                                children: (
                                    <>
                                        <Form.Item name="sorular" label={t("kurs.sorular")}>
                                            <List
                                                footer={
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            setKursSoruEkleModalOpen(true);
                                                        }}
                                                    >
                                                        {t("kursSoru.soruEkle")}
                                                    </Button>
                                                }
                                                bordered
                                                dataSource={kursSorulari}
                                                renderItem={(item) => (
                                                    <List.Item key={item.id} suffix={<EditOutlined />}>
                                                        <Button
                                                            type="text"
                                                            icon={<EditOutlined />}
                                                            onClick={() => {
                                                                navigate(`/kursSoruGuncelle/${item.id}`);
                                                            }}
                                                        />
                                                        <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: item.soru }} />
                                                    </List.Item>
                                                )}
                                            />
                                        </Form.Item>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Form>
                <Modal
                    key="kursBolumEkleModalForm"
                    open={kursBolumEkleModalOpen}
                    onOk={() => {
                        setKursBolumEkleModalOpen(false);
                    }}
                    onCancel={() => {
                        setKursBolumEkleModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setKursBolumEkleModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="kursBolumEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setKursBolumEkleModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <KursBolumEkle kursID={id} />
                </Modal>
                <Modal
                    key="kursSoruEkleModalForm"
                    open={kursSoruEkleModalOpen}
                    onOk={() => {
                        setKursSoruEkleModalOpen(false);
                    }}
                    onCancel={() => {
                        setKursSoruEkleModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setKursSoruEkleModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="kursSoruEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setKursSoruEkleModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <KursSoruEkle kursID={id} />
                </Modal>
            </Content>
        </Layout>
    );
}

export default KursGuncelle;
