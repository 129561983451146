import { Avatar, Button, Image, Input, Layout, Modal, notification, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import KmyHeader from "./KmyHeader";
import EkipmanEkle from "./EkipmanEkle";
import { useEkipmanlariGetir } from "../hooks/useEkipman";
const { Header, Content } = Layout;

function EkipmanList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { ekipmanlar } = useEkipmanlariGetir();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(ekipmanlar);
        setFilteredSource(ekipmanlar);
        setIsPending(false);
    }, [ekipmanlar]);

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );
    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const isim = item.isim ? item.isim.toLowerCase() : "";
                    return isim.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            key: (p) => p.ekipmanID,
            render: (p) => (
                <Button
                    type="text"
                    key={p.ekipmanID}
                    icon={<EditOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/ekipmanGuncelle/${p.ekipmanID}`);
                    }}
                />
            ),
        },
        {
            title: t("ekipman.isim"),
            dataIndex: "isim",
            key: (p) => p.ekipmanID & "isim",
            sorter: (a, b) => a.isim.localeCompare(b.isim),
        },
        {
            title: t("ekipman.marka"),
            dataIndex: "marka",
            key: (p) => p.ekipmanID & "marka",
            sorter: (a, b) => a.marka.localeCompare(b.marka),
        },
        {
            title: t("ekipman.model"),
            dataIndex: "model",
            key: (p) => p.ekipmanID & "model",
            sorter: (a, b) => a.model.localeCompare(b.model),
        },
        {
            title: t("ekipman.konum"),
            dataIndex: "konum",
            key: (p) => p.ekipmanID & "konum",
            sorter: (a, b) => a.konum.localeCompare(b.konum),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.ekipmanList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Input
                    key="searchBox"
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="ekipmanList" dataSource={filteredSource} columns={columns} onChange={onChange} />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="ekipmanEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <EkipmanEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default EkipmanList;
