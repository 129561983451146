import ilList from "../constant/il.json";
import ilceList from "../constant/ilce.json";

import { Avatar, Button, Icon, Image, Input, Layout, Modal, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import CompanyAdd from "./FirmaEkle";
import { useFirmalariGetir } from "../hooks/useFirma";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import KmyHeader from "./KmyHeader";

const { Header, Content } = Layout;

function FirmaList() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const yetkiler = [99999];
    const { firmalar } = useFirmalariGetir();

    var ekleButon = (
        <Button
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    useEffect(() => {
        setDataSource(firmalar);
        setFilteredSource(firmalar);
        setIsPending(false);
        if (!yetkiler.includes(10201) && !yetkiler.includes(99999)) ekleButon = null;
    }, [firmalar]);

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const firmaismi = item.firmaismi ? item.firmaismi.toLowerCase() : "";
                    const title = item.title ? item.title.toLowerCase() : "";
                    const vergiNo = item.vergiNo ? item.vergiNo.toLowerCase() : "";
                    const comparisonString = `${firmaismi} ${title} ${vergiNo}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: t("firma.firmaismi"),
            key: "firmaismi",
            sorter: (a, b) => a.firmaismi.localeCompare(b.firmaismi),
            render: (p) => (
                <div className="flex items-center" key={`div${p.firmaID}`}>
                    {(yetkiler.includes(10200) || yetkiler.includes(99999)) && (
                        <Button
                            type="text"
                            key={p.firmaID}
                            icon={<EditOutlined />}
                            style={{ marginRight: 5 }}
                            onClick={() => {
                                navigate(`/firmaGuncelle/${p.firmaID}`);
                            }}
                        />
                    )}
                    <Avatar shape="square" src={`${p.logoURL}`} style={{ width: 96, height: 32, borderRadius: 0, marginRight: 5 }}></Avatar>
                    {p.firmaismi}
                </div>
            ),
        },
        {
            title: t("firma.il"),
            dataIndex: "il",
            key: "il",
            filterSearch: true,
            render: (p) => {
                {
                    const bulunan = Object.values(ilList).find((e) => e.value === p);
                    return bulunan ? bulunan.text : "";
                }
            },
            filters: ilList,
            onFilter: (value, record) => record.il.includes(value),
        },
        {
            title: t("firma.ilce"),
            dataIndex: "ilce",
            key: "ilce",
            filterSearch: true,
            render: (p) => {
                {
                    const bulunan = Object.values(ilceList).find((e) => e.value === p);
                    return bulunan ? bulunan.text : "";
                }
            },
        },
        {
            title: "KmySistem",
            width: 85,
            dataIndex: "kmyMusterisiMi",
            key: "kmyMusterisiMi",
            render: (p) => (
                <div key={`kmy${p.id}`} style={{ width: "100%", textAlign: "center" }}>
                    {p ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />}
                </div>
            ),
            filters: [
                { text: "Evet", value: true },
                { text: "Hayır", value: false },
            ],
            onFilter: (value, record) => record.kmyMusterisiMi === value,
        },
        {
            title: "TMGD",
            width: 85,
            dataIndex: "tmgdMusterisiMi",
            key: "tmgdMusterisiMi",
            render: (p) => (
                <div key={`tmgd`} style={{ width: "100%", textAlign: "center" }}>
                    {p ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />}
                </div>
            ),
            filters: [
                { text: "Evet", value: true },
                { text: "Hayır", value: false },
            ],
            onFilter: (value, record) => record.tmgdMusteriMi === value,
        },
        {
            title: "UETDS",
            width: 85,
            dataIndex: "uetdsMusterisiMi",
            key: "uetdsMusterisiMi",
            render: (p) => (
                <div key={`uetds${p.id}`} style={{ width: "100%", textAlign: "center" }}>
                    {p ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />}
                </div>
            ),
            filters: [
                { text: "Evet", value: true },
                { text: "Hayır", value: false },
            ],
            onFilter: (value, record) => record.uetdsMusterisiMi === value,
        },
        {
            title: t("firma.vergiNo"),
            dataIndex: "vergiNo",
            key: "vergiNo",
            filterMode: "tree",
            filterSearch: true,
            onFilter: (value, record) => record.vergiNo.startsWith(value),
            sorter: (a, b) => a.vergiNo.localeCompare(b.vergiNo),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        //console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.firmaList" buttons={[ekleButon]} backURL="/" />
                <Input
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="firmaList" dataSource={filteredSource} columns={columns} onChange={onChange} />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="companyAddForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <CompanyAdd />
                </Modal>
            </Content>
        </Layout>
    );
}

export default FirmaList;
