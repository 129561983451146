import { Button, Form, Input, Layout, notification, Space } from "antd";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useEnvanterEkle } from "../hooks/useEnvanter";
import UnList from "./UnList";
import KmyHeader from "./KmyHeader";

const { Content } = Layout;

function EnvanterEkle() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { envanterEkle } = useEnvanterEkle();
    const [api, contextHolder] = notification.useNotification();
    const [envUnID, setEnvUnID] = useState();
    const [envIsim, setEnvIsim] = useState();
    const [envErg, setEnvErg] = useState();

    const onSubmit = (e) => {
        console.log("envanter ekle");
        if (envUnID) {
            e.isim = envIsim;
            e.kod = null;
            e.unID = envUnID;
            e.maddeHali = null;
            e.ambalaj = null;
            e.hKodlari = null;
            e.pKodlari = null;
            e.donma = null;
            e.erime = null;
            e.parlama = null;
            e.nfpaSaglik = null;
            e.nfpaYanicilik = null;
            e.nfpaReaktivite = null;
            e.nfpaOzelnot = null;
            e.ghsEtiket = null;
            e.adrEtiket = null;
            e.casNo = null;
            e.ergNo = envErg;
            e.eacNo = null;
            e.ptSaglik = t(`erg.${envErg}.ph_health`);
            e.ptYangin = t(`erg.${envErg}.ph_fire`);
            e.kamuGuvenligi = t(`erg.${envErg}.ps`);
            e.koruyucuEkipman = t(`erg.${envErg}.ps_pc`);
            e.tahliyeBasta = t(`erg.${envErg}.ph_evac`);
            e.bastaGunduz = null;
            e.bastaGece = null;
            e.tahliyeSizinti = null;
            e.sizintiGunduz = null;
            e.sizintiGece = null;
            e.tahliyeYangin = null;
            e.yanginGunduz = null;
            e.yanginGece = null;
            e.mudahaleYangin = t(`erg.${envErg}.er_fire`);
            e.mudahaleSizinti = t(`erg.${envErg}.er_spill`);
            e.ilkyardim = t(`erg.${envErg}.er_aid`);
        } else {
            e.isim = envIsim;
        }
        envanterEkle(e);
    };

    return (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="envanterEkleForm"
                    form={form}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader key="envanterGuncelleKmyHeader" caller="sayfaBaslik.envanter" backURL="/envanterList" />
                    {contextHolder}
                    <Space style={{ width: "100%", justifyContent: "center", marginBottom: 20, whiteSpace: "pre-line", textAlign: "center" }}>
                        {t("envanter.envanterEkle")}
                    </Space>
                    <Space style={{ width: "100%", justifyContent: "center", marginBottom: 20 }}>
                        <Button key="yokKaydet" type="primary" form="envanterEkleForm" htmlType="submit" disabled={envUnID || !envIsim}>
                            {t("envanter.unYok")}
                        </Button>
                        <Button key="varKaydet" type="primary" form="envanterEkleForm" htmlType="submit" disabled={!envUnID || !envIsim}>
                            {t("envanter.unVar")}
                        </Button>
                    </Space>
                    <Form.Item name="isim" label={t("envanter.isim")} rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}>
                        <Input
                            placeholder={t("envanter.isimHint")}
                            onChange={(obj) => {
                                setEnvIsim(obj.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="unID" label={t("envanter.unID")}>
                        <UnList unID={envUnID} setUnID={setEnvUnID} ergNo={envErg} setErgNo={setEnvErg} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default EnvanterEkle;
